import React from 'react';
import styled from 'styled-components';
import { ButtonLookDiv } from '../../../shared/components/Common/Button';
import Icon, { Icons } from '../../../shared/components/Common/Icon';
import { DEVICE } from '../../../shared/styles';

const NoticeContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: ${({ theme }) => theme.announcement.background};
  border: 1px solid ${({ theme }) => theme.announcement.border};
  border-radius: 8px;
  width: 100%;
  max-width: 1200px;
  box-sizing: border-box;
  padding: 14px;
  transition: background-color 0.15s ease-in;

  @media ${DEVICE.mobileWide} {
    flex-direction: column;
    padding: 8px 16px 16px;

    > a {
      width: 100%;
    }
  }
`;

const ContentHeader = styled.div`
  flex: 1;
  margin-left: 12px;
  @media ${DEVICE.mobileWide} {
    font-size: 16px;
    margin-left: 0;
    margin-top: 12px;
  }
`;

const Title = styled.span`
  font-size: 15px;
  font-weight: 600;
  color: ${({ theme }) => theme.notice.title};

  @media ${DEVICE.mobileWide} {
    font-size: 14px;
  }
`;

const Message = styled.div`
  margin-top: 4px;
  font-size: 14px;
  font-weight: 300;
  color: ${({ theme }) => theme.notice.message};
  white-space: pre-line;

  @media ${DEVICE.mobileWide} {
    font-size: 13px;
  }
`;

const NoticeButtonDiv = styled(ButtonLookDiv)`
  margin-left: 12px;

  @media ${DEVICE.mobileWide} {
    width: 100%;
    margin: 12px 0 0;
  }
`;

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
  width: 22px;
  height: 22px;

  @media ${DEVICE.mobileWide} {
    width: 24px;
    height: 24px;
  }
`;

export interface NoticeProps {
  variant?: 'warning' | 'default';
  title: string;
  message?: string;
  linkHref?: string;
  linkText?: string;
}

const Notice: React.FC<NoticeProps> = ({
  variant = 'default',
  title,
  message,
  linkHref,
  linkText,
}) => (
  <NoticeContainer>
    <StyledIcon name={variant === 'warning' ? Icons.CAUTION : Icons.INFO} />
    <ContentHeader>
      <Title>{title}</Title>
      {message && <Message>{message}</Message>}
    </ContentHeader>
    {linkHref && linkText && (
      <a href={linkHref}>
        <NoticeButtonDiv
          buttonTheme={variant === 'default' ? 'light' : 'caution'}
          buttonSize="medium"
          text={linkText}
        />
      </a>
    )}
  </NoticeContainer>
);

export default Notice;
