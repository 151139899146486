import Link, { LinkProps } from 'next/link';
import styled from 'styled-components';
import Icon, { Icons } from '../../../shared/components/Common/Icon';
import {
  BLOCK_PADDING,
  BLOCK_PADDING_MOBILE,
} from '../../../shared/components/Slider';
import { DEVICE } from '../../../shared/styles';
import { COLORS } from '../../../shared/styles/theme';
import { BLOCKS_MARGIN, BLOCKS_MARGIN_MOBILE } from '../../Genre/constants';

const CalendarButtonContainer = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 12px 16px 12px 24px;
  border-radius: 10px;
  border: 0.5px solid ${COLORS.white_10};
  background: linear-gradient(
    180deg,
    ${COLORS.charcoal} 0%,
    ${COLORS.ink} 100%
  );
  margin: 0 ${BLOCK_PADDING}px ${BLOCKS_MARGIN}px ${BLOCK_PADDING}px;
  max-width: 1200px;
  @media ${DEVICE.mobileWide} {
    margin: 0 ${BLOCK_PADDING_MOBILE}px ${BLOCKS_MARGIN_MOBILE}px
      ${BLOCK_PADDING_MOBILE}px;
  }
`;

const CalendarButtonOverlay = styled.div`
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  transition: background 0.15s ease-in;

  &:hover {
    background: ${COLORS.white_05};
  }
`;

const CalendarIcon = styled(Icon)`
  height: 25px;
  width: 25px;

  @media ${DEVICE.mobileWide} {
    height: 22px;
    width: 22px;
  }
`;

const CalendarText = styled.div`
  flex-grow: 1;
  font-size: 18px;
  font-weight: 600;

  @media ${DEVICE.mobileWide} {
    font-size: 15px;
  }
`;

const LinkIcon = styled(Icon)`
  height: 32px;
  width: 32px;
  color: ${COLORS.primary};

  @media ${DEVICE.mobileWide} {
    height: 22px;
    width: 22px;
  }
`;

interface CalendarButtonProps extends LinkProps {
  text: string;
}

export const CalendarButton: React.FC<CalendarButtonProps> = ({
  text,
  ...restProps
}) => {
  return (
    <Link legacyBehavior={false} {...restProps}>
      <CalendarButtonContainer>
        <CalendarButtonOverlay />
        <CalendarIcon name={Icons.CALENDAR} />
        <CalendarText>{text}</CalendarText>
        <LinkIcon name={Icons.HALF_ARROW_RIGHT} />
      </CalendarButtonContainer>
    </Link>
  );
};
