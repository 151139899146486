import React, { HTMLAttributes } from 'react';
import { animated, config, useSpring } from 'react-spring';
import styled from 'styled-components';

type Visibility = React.CSSProperties['visibility'];

const Container = styled(animated.div)<{
  $show: boolean;
}>`
  display: ${({ $show }) => ($show ? 'block' : 'none')};
  padding: 16px;
  background-color: ${({ theme }) => theme.popup.wrapper};
  border-radius: 10px;
  box-shadow: 0px 8px 32px 0px ${({ theme }) => theme.popup.wrapperShadow};
  z-index: ${({ $show }) => ($show ? 10 : 1)};
  opacity: ${({ $show }) => ($show ? 1 : 0)};
  transition: opacity 0.15s ease-in 20ms;
`;

interface PopupProps extends HTMLAttributes<HTMLDivElement> {
  show?: boolean;
}

const Popup: React.FC<PopupProps> = ({ show = true, children }) => {
  const springProps = useSpring({
    transform: show ? 'scale(1)' : 'scale(0.9)',
    config: config.wobbly,
  });
  const visibilityProps = useSpring({
    visibility: show ? ('visible' as Visibility) : ('hidden' as Visibility),
    delay: show ? 0 : 300,
  });
  return (
    <Container $show={show} style={{ ...springProps, ...visibilityProps }}>
      {children}
    </Container>
  );
};

export default Popup;
