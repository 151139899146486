import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import HorizontalLogo from '../../../assets/icons/logo/Logo_U-NEXT_Horizontal.svg';
import VerticalLogo from '../../../assets/icons/logo/Logo_U-NEXT_Vertical.svg';
import {
  Button,
  ButtonLookDiv,
} from '../../../shared/components/Common/Button';
import Icon, { Icons } from '../../../shared/components/Common/Icon';
import ImageFader from '../../../shared/components/Common/ImageFader';
import { GlobalConfig } from '../../../shared/constants';
import useHostInfo from '../../../shared/hooks/useHostInfo';
import useLoginUrl from '../../../shared/hooks/useLoginUrl';
import { DEVICE } from '../../../shared/styles';
import { COLORS } from '../../../shared/styles/theme';

const messages = defineMessages({
  catchphrase: {
    id: 'genre.notloggedinbanner.catchphrase',
    defaultMessage: '観るのも読むのも、これひとつ。',
  },
  description: {
    id: 'genre.notloggedinbanner.description',
    defaultMessage: `映画 / ドラマ / アニメから、マンガや雑誌といった電子書籍まで。U-NEXTひとつで楽しめます。`,
  },
  signupButton: {
    id: 'genre.notloggedinbanner.button',
    defaultMessage: '今すぐ31日間 無料トライアル',
  },
  login: {
    id: 'genre.notloggedinbanner.login',
    defaultMessage: 'ログインはこちら',
  },
  campaigns: {
    id: 'genre.notloggedinbanner.campaigns',
    defaultMessage: 'キャンペーン・イチオシ作品の情報を発信中',
  },
  twitter: {
    id: 'genre.notloggedinbanner.twitter',
    defaultMessage: 'フォローする',
  },
  facebook: {
    id: 'genre.notloggedinbanner.facebook',
    defaultMessage: 'Facebookでチェック',
  },
});

const ImageContainer = styled.div`
  position: absolute;
  height: 540px;
  width: 100%;
  z-index: -1;

  @media ${DEVICE.mobileWide} {
    height: 312px;
  }
`;

const StyledImageFader = styled(ImageFader)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

const BannerOverlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  ${({ theme }) => css`
    background: linear-gradient(
      -180deg,
      ${theme.background.gradient_40} 0%,
      ${theme.background.default} 100%
    );
  `}
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  padding: 182px 56px 0;

  h1 {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    margin-top: 40px;
  }

  p {
    margin: 0;
    padding: 0;
    margin-top: 20px;
    font-size: 16px;
    color: ${COLORS.white_75};
  }

  @media ${DEVICE.mobileWide} {
    padding: 136px 32px 0;
    h1 {
      font-size: 20px;
    }

    p {
      font-size: 12px;
    }
  }
`;

const VerticalLogoContainer = styled.div`
  svg {
    width: 80px;
  }
  @media ${DEVICE.exceptMobileWide} {
    display: none;
  }
`;

const HorizontalLogoContainer = styled.div`
  svg {
    width: 286px;
  }
  @media ${DEVICE.mobileWide} {
    display: none;
  }
`;

const StyledButtonDiv = styled(ButtonLookDiv)`
  width: 360px;
  margin-top: 40px;

  @media ${DEVICE.mobileWide} {
    width: 294px;
  }
`;

const LoginButton = styled(Button)`
  margin-top: 24px;
  @media ${DEVICE.mobileWide} {
    margin-top: 32px;
  }
`;

const SocialsContainer = styled.div`
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;

  color: ${COLORS.white_50};
  font-size: 12px;

  @media ${DEVICE.mobileWide} {
    margin-top: 40px;
  }
`;

const SocialButtonsContainer = styled.div`
  margin-top: 8px;
  display: flex;

  & > *:not(:first-child) {
    margin-left: 12px;
  }

  @media ${DEVICE.mobileWide} {
    width: 100%;
    a {
      flex: 1;
    }
  }
`;

const SocialButton = styled.div`
  display: flex;
  width: 140px;
  padding: 4px 0px;
  justify-content: center;
  align-items: center;

  border-radius: 4px;
  border: 1px solid ${COLORS.white_05};
  background: ${COLORS.white_15};

  color: ${COLORS.white};
  font-size: 10px;

  transition: all 0.15s ease-in;

  &:hover {
    background: ${COLORS.white_30};
  }
`;

const FollowIcon = styled(Icon)`
  height: 16px;
  width: 16px;
  margin-right: 4px;
  flex-shrink: 0;
`;

interface NotLoggedInBannerProps {
  backgroundImages: string[];
}

const NotLoggedInBanner: React.FC<NotLoggedInBannerProps> = ({
  backgroundImages,
}) => {
  const intl = useIntl();
  const { getLoginUrl } = useLoginUrl();
  const { isAeon } = useHostInfo();

  return (
    <>
      <ImageContainer>
        <StyledImageFader backgroundImages={backgroundImages} />
        <BannerOverlay />
      </ImageContainer>
      <ContentContainer data-ucn="notLoggedInBanner-content">
        <VerticalLogoContainer>
          <VerticalLogo />
        </VerticalLogoContainer>
        <HorizontalLogoContainer>
          <HorizontalLogo />
        </HorizontalLogoContainer>
        <h1>
          <FormattedMessage {...messages.catchphrase} />
        </h1>
        <p>
          <FormattedMessage {...messages.description} />
        </p>
        <a href={`${GlobalConfig.URLS.STATIC}/welcome${isAeon ? '/aeon' : ''}`}>
          <StyledButtonDiv
            buttonTheme="attention"
            buttonSize="large"
            text={intl.formatMessage(messages.signupButton)}
          />
        </a>
        <LoginButton
          buttonTheme="text"
          buttonSize="large"
          buttonSizeMobile="medium"
          onClick={() => (location.href = getLoginUrl())}
          text={intl.formatMessage(messages.login)}
        />
        <SocialsContainer>
          <span>{intl.formatMessage(messages.campaigns)}</span>
          <SocialButtonsContainer>
            <a
              href="https://twitter.com/intent/follow?screen_name=watch_unext"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialButton>
                <FollowIcon name={Icons.SNS_X} />
                <span>{intl.formatMessage(messages.twitter)}</span>
              </SocialButton>
            </a>
            <a
              href="https://facebook.com/WATCH.UNEXT/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialButton>
                <FollowIcon name={Icons.SNS_FACEBOOK} />
                <span>{intl.formatMessage(messages.facebook)}</span>
              </SocialButton>
            </a>
          </SocialButtonsContainer>
        </SocialsContainer>
      </ContentContainer>
    </>
  );
};

export default NotLoggedInBanner;
