import React from 'react';
import styled from 'styled-components';
import { cosmo_homeQuery } from '../../../__generated__/globalTypes';
import Announcement from '../../../shared/components/Announcement';

const AnnouncementLink = styled.a`
  display: inline-block;
  width: 100%;
`;

interface AnnouncementListProps {
  announcements: cosmo_homeQuery['webfront_infoList'];
}

const AnnouncementList: React.FC<AnnouncementListProps> = ({
  announcements,
}) => {
  const filteredAnnouncements = announcements.filter(
    (a) => new Date(a.ttl).getTime() > Date.now()
  );

  if (filteredAnnouncements.length === 0) {
    return null;
  }

  return (
    <>
      {filteredAnnouncements.map((item, index) => (
        <AnnouncementLink href={item.url} key={`info-list-item-${index}`}>
          <Announcement>{item.title}</Announcement>
        </AnnouncementLink>
      ))}
    </>
  );
};

export default AnnouncementList;
