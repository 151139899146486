import { useRouter } from 'next/router';
import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import {
  cosmo_calendar_settingQuery,
  cosmo_calendar_settingQueryVariables,
  cosmo_homeQuery,
  cosmo_homeQueryVariables,
  cosmo_leadTitleQuery,
  cosmo_upsellQuery,
  cosmo_upsell_guideQuery,
} from '../../__generated__/globalTypes';
import {
  LeanbackContext,
  LeanbackGenre,
} from '../../shared/components/Leanback/LeanbackContext';
import MetaTags from '../../shared/components/MetaTags';
import {
  BLOCK_PADDING,
  BLOCK_PADDING_MOBILE,
} from '../../shared/components/Slider';
import { GenreUrl, GlobalConfig, UrlQueryParams } from '../../shared/constants';
import messages from '../../shared/constants/meta/genre';
import useBrowser from '../../shared/hooks/useBrowser';
import useClientQuery from '../../shared/hooks/useClientQuery';
import { useUserInfo } from '../../shared/hooks/useUserInfo';
import { DEVICE } from '../../shared/styles';
import UserAgentContext from '../../shared/UserAgentContext';
import { CalendarButton } from '../Calendar/CalendarButton';
import { GET_CALENDAR_SETTING } from '../Calendar/gql';
import type { HomeLog } from '../Log/__types__/home';
import { getKafkaClient } from '../Log/kafkaClient';
import AdultBanner from './AdultBanner';
import AnnouncementList from './AnnouncementList';
import AppGuidance from './AppGuidance';
import { BLOCKS_MARGIN, BLOCKS_MARGIN_MOBILE, GenreProps } from './constants';
import GenreBanner from './GenreBanner';
import GenreContainer, { BlocksContainer } from './GenreContainer';
import GenreLead, { GenreLeadSpacer } from './GenreLead';
import { GET_HOME, GET_LEAD_TITLE, GET_UPSELL, GET_UPSELL_GUIDE } from './gql';
import LabelBlock from './LabelBlock';
import { messages as genreMessages } from './messages';
import Notice from './Notice';
import NotLoggedInBanner from './NotLoggedInBanner';
import UserBlocks from './UserBlocks';
import { getWarningProps } from './utils';
import { VideoGenreBlocks } from './VideoGenreBlocks';

const AnnouncementListLayout = styled.div`
  position: relative;
  padding: 0 ${BLOCK_PADDING}px;
  margin-bottom: ${BLOCKS_MARGIN}px;

  &:empty {
    display: none;
  }

  > *:not(:first-child) {
    margin-top: 6px;
  }

  @media ${DEVICE.mobileWide} {
    padding: 0 ${BLOCK_PADDING_MOBILE}px;
    margin-bottom: ${BLOCKS_MARGIN_MOBILE}px;
  }
`;

const VideoGenre: React.FC<GenreProps> = ({
  genreDisplayCode,
  pageNumber = 1,
}) => {
  const [firstRowImages, setFirstRowImages] = useState<string[]>([]);
  const router = useRouter();
  const isAdult = genreDisplayCode === 'ADULT';
  const intl = useIntl();
  const browser = useBrowser();
  const { data: userInfoData, loading: userInfoLoading } = useUserInfo({
    fetchPolicy: 'cache-first',
  });
  const userInfo = userInfoData?.userInfo;
  const isLoggedIn = !userInfoLoading && !!userInfo?.id;
  const { hasUserCookie } = useContext(UserAgentContext);

  const { leanbackData, getLeanbackByDisplayCode } =
    useContext(LeanbackContext);

  let leanbackGenre: LeanbackGenre | undefined;

  if (genreDisplayCode) {
    leanbackGenre = getLeanbackByDisplayCode(leanbackData, genreDisplayCode);
  }

  const { data: leadTitleData, error: leadTitleError } =
    useClientQuery<cosmo_leadTitleQuery>(GET_LEAD_TITLE, {
      skip: !!genreDisplayCode || !isLoggedIn,
    });

  const { data: upsellData } = useClientQuery<cosmo_upsellQuery>(GET_UPSELL, {
    skip: !!genreDisplayCode || !isLoggedIn,
  });

  const { data: upsellGuideData } = useClientQuery<cosmo_upsell_guideQuery>(
    GET_UPSELL_GUIDE,
    {
      skip: !upsellData?.webfront_upsell.canDisplay,
    }
  );

  const { data: calendarSettingData } = useClientQuery<
    cosmo_calendar_settingQuery,
    cosmo_calendar_settingQueryVariables
  >(GET_CALENDAR_SETTING, {
    variables: { genre: genreDisplayCode ?? '' },
    skip: !genreDisplayCode,
  });

  const { data: homeData } = useClientQuery<
    cosmo_homeQuery,
    cosmo_homeQueryVariables
  >(GET_HOME, {
    skip: !!genreDisplayCode,
    variables: {
      infoCheck: router.query.check === '1',
    },
    errorPolicy: 'all',
  });

  const osName = browser.getOSName(true);
  const isMobileOS = osName === 'ios' || osName === 'android';
  const isMobileSilk =
    osName === 'android' && browser.getBrowserName() === 'Amazon Silk';

  const warningProps = getWarningProps(userInfo, intl);

  useEffect(() => {
    if (leadTitleData?.webfront_leadTitle.leadCode) {
      getKafkaClient().trackViewLog<HomeLog>('home', {
        lead_sakuhin_code: leadTitleData?.webfront_leadTitle.leadCode,
        video_block_list: [], // TODO: WF-11579
      });
    }
  }, [leadTitleData?.webfront_leadTitle.leadCode]);

  // use new adult banner when
  // 1. A/B test is enabled
  // 2. user's PFID suffix is included in targetUserPFIDSuffix
  let useNewAdultBanner = false;
  if (isAdult && typeof document !== 'undefined' && window.__REMOTE_CONFIG__) {
    const pfidSuffix = Number(userInfo?.userPlatformId?.slice(-1));
    const targetUserPFIDSuffix =
      window.__REMOTE_CONFIG__.adultBannerABTest?.targetUserPFIDSuffix;

    useNewAdultBanner =
      !!window.__REMOTE_CONFIG__.adultBannerABTest?.enable &&
      !!targetUserPFIDSuffix?.includes(pfidSuffix);
  }

  /**
   * It's not necessary to wait for isLoggedIn becoming true because API can already read user cookie
   * By doing this, we can save a round trip time to the server
   */
  const renderLoggedInContents = !!(isLoggedIn || hasUserCookie);

  return (
    <GenreContainer>
      {genreDisplayCode && leanbackGenre && (
        <MetaTags
          title={intl.formatMessage(messages.defaultTitle, {
            genreDisplayLabel: leanbackGenre.name,
          })}
          description={
            messages[genreDisplayCode as keyof typeof messages]
              ? intl.formatMessage(
                  messages[genreDisplayCode as keyof typeof messages]
                )
              : ''
          }
          keywords={intl.formatMessage(messages.defaultKeywords, {
            genreDisplayLabel: leanbackGenre.name,
          })}
          canonicalLink={
            genreDisplayCode ? `/genre/${GenreUrl[genreDisplayCode]}` : ''
          }
          breadcrumbs={[
            { name: 'HOME', pathname: '/' },
            {
              name: leanbackGenre.name,
              pathname: `/genre/${GenreUrl[genreDisplayCode]}`,
            },
          ]}
        />
      )}
      {genreDisplayCode && leanbackGenre && (
        <>
          {isAdult ? (
            <AdultBanner
              searchGenreId={leanbackGenre.searchMenu.id}
              isNewUI={useNewAdultBanner}
            />
          ) : (
            <GenreBanner
              title={leanbackGenre.name}
              searchGenreId={leanbackGenre.searchMenu.id}
              backgroundImages={firstRowImages}
            />
          )}
        </>
      )}
      {!genreDisplayCode &&
        renderLoggedInContents &&
        !leadTitleError &&
        (leadTitleData ? (
          <GenreLead {...leadTitleData.webfront_leadTitle} />
        ) : (
          <GenreLeadSpacer />
        ))}
      {!genreDisplayCode && !renderLoggedInContents && (
        <NotLoggedInBanner backgroundImages={firstRowImages} />
      )}
      <BlocksContainer>
        {!genreDisplayCode &&
          (homeData ||
            upsellData?.webfront_upsell.canDisplay ||
            warningProps) && (
            <AnnouncementListLayout>
              {warningProps ? (
                <Notice
                  variant="warning"
                  title={warningProps.title}
                  message={warningProps.message}
                  linkHref={warningProps.buttonUrl}
                  linkText={warningProps.buttonText}
                />
              ) : (
                !!upsellData?.webfront_upsell.canDisplay &&
                upsellGuideData && (
                  <Notice
                    title={intl.formatMessage(
                      genreMessages.videoUpsellNoticeText
                    )}
                    linkHref={
                      upsellGuideData.webfront_upsell.guide.subscriptionDisabled
                        ? `${GlobalConfig.URLS.REGISTRATION}/apply/check/vod`
                        : upsellGuideData.webfront_upsell.guide.destinationUrl
                    }
                    linkText={intl.formatMessage(
                      genreMessages.videoUpsellNoticeButtonText
                    )}
                  />
                )
              )}
              {!!homeData && (
                <AnnouncementList announcements={homeData.webfront_infoList} />
              )}
            </AnnouncementListLayout>
          )}
        {!genreDisplayCode && isLoggedIn && isMobileOS && (
          <AppGuidance
            storeType={
              osName === 'ios' ? 'ios' : isMobileSilk ? 'amazon' : 'google'
            }
            parentalLock={userInfo?.parentalLock ?? false}
          />
        )}
        {calendarSettingData?.calendarFeatureSettings.enabled && (
          <CalendarButton
            text={
              calendarSettingData.calendarFeatureSettings.displayText
                ?.buttonText ?? ''
            }
            href={{
              pathname: '/calendar',
              query: {
                [UrlQueryParams.CALENDAR_FILTER_GENRE]: genreDisplayCode,
              },
            }}
          />
        )}
        {!genreDisplayCode && renderLoggedInContents && <UserBlocks />}
        {homeData?.labelBlock && homeData.labelBlock.labelList.length > 0 && (
          <LabelBlock {...homeData.labelBlock} />
        )}
        <VideoGenreBlocks
          genreCode={leanbackGenre?.id}
          genrePath={genreDisplayCode ? GenreUrl[genreDisplayCode] : undefined}
          pageNumber={pageNumber}
          baseLink={`/genre/${
            GenreUrl[genreDisplayCode as keyof typeof GenreUrl]
          }`}
          setFirstRowImages={setFirstRowImages}
        />
      </BlocksContainer>
    </GenreContainer>
  );
};

export default VideoGenre;
