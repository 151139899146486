import { gql } from '@apollo/client';

export const GET_CALENDAR_SETTING = gql`
  query cosmo_calendar_setting($genre: String!) {
    calendarFeatureSettings(menuItem: { type: GENRE, value: $genre }) {
      enabled
      hasEvents
      searchableTags {
        defaultText
        key
        values {
          value
          displayName
        }
      }
      displayText {
        buttonText
        emptyMessage
      }
    }
  }
`;

export const GET_CALENDAR_EVENTS = gql`
  query cosmo_calendar_events(
    $from: DateTime!
    $to: DateTime!
    $tags: [CalendarSearchTagInput!]
  ) {
    calendarEvents(from: $from, to: $to, tags: $tags) {
      __typename
      contentCode {
        ... on LiveContentCode {
          liveCode
        }
        ... on VodContentCode {
          vodCode
        }
      }
      date
      id
      imageUrl
      name
      ... on NoTeamSportEvent {
        language
        state
        league
      }
      ... on NTeamSportEvent {
        language
        state
        league
      }
      ... on TwoTeamSportEvent {
        language
        state
        round
        league
        homeTeam {
          name
          id
          imageUrl
        }
        awayTeam {
          name
          id
          imageUrl
        }
      }
    }
  }
`;
