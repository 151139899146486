import React from 'react';
import { FormattedDate } from 'react-intl';
import styled from 'styled-components';
import { DEVICE } from '../../styles';
import Icon, { Icons } from '../Common/Icon';

const AnnouncementContainer = styled.div`
  background-color: ${({ theme }) => theme.announcement.background};
  border: 1px solid ${({ theme }) => theme.announcement.border};
  border-radius: 8px;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  box-sizing: border-box;
  padding: 12px 14px;
  cursor: pointer;
  transition: background-color 0.15s ease-in;

  &:hover {
    background-color: ${({ theme }) => theme.announcement.backgroundHover};
  }

  @media ${DEVICE.mobileWide} {
    align-items: flex-start;
    padding: 8px 16px 8px 12px;
    font-size: 12px;
  }
`;

const InfoIcon = styled(Icon)`
  width: 22px;
  height: 22px;
  @media ${DEVICE.mobileWide} {
    width: 24px;
    height: 24px;
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  padding: 0 12px;

  @media ${DEVICE.mobileWide} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const DateContainer = styled.div`
  color: ${({ theme }) => theme.announcement.dateColor};
  margin-right: 12px;
  font-size: 14px;

  @media ${DEVICE.mobileWide} {
    padding-bottom: 4px;
    margin: 0;
    font-size: 12px;
  }
`;

const AnnouncementText = styled.div`
  flex: 1;
  font-size: 15px;

  @media ${DEVICE.mobileWide} {
    font-size: 13px;
  }
`;

const PushLinkIcon = styled(Icon)`
  width: 20px;
  height: 20px;

  @media ${DEVICE.mobileWide} {
    width: 18px;
    height: 18px;
  }
  ${AnnouncementContainer}:hover & {
    display: none;
  }
`;

const PushLinkActiveIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  display: none;
  background-color: ${({ theme }) => theme.announcement.activeButton};
  border-radius: 4px;

  @media ${DEVICE.mobileWide} {
    width: 18px;
    height: 18px;
  }

  ${AnnouncementContainer}:hover & {
    display: block;
  }
`;

interface AnnouncementProps {
  time?: Date;
  children: React.ReactNode;
}

const Announcement: React.FC<AnnouncementProps> = ({ time, children }) => {
  return (
    <AnnouncementContainer>
      <InfoIcon name={Icons.INFO} />
      <MainContainer>
        {time && (
          <DateContainer>
            <FormattedDate
              value={time}
              year="numeric"
              month="long"
              day="numeric"
            />
          </DateContainer>
        )}
        <AnnouncementText>{children}</AnnouncementText>
      </MainContainer>
      <PushLinkIcon name={Icons.PUSH_LINK} />
      <PushLinkActiveIcon name={Icons.PUSH_LINK_ACTIVE} />
    </AnnouncementContainer>
  );
};

export default Announcement;
