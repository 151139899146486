import { defineMessages } from 'react-intl';

const messages = defineMessages({
  defaultTitle: {
    id: 'genre.defaultTitle',
    defaultMessage:
      '{genreDisplayLabel} - 動画配信 | U-NEXT 31日間無料トライアル',
    description: 'Genre default title',
  },
  YOUGA: {
    id: 'genre.yougaDescription',
    defaultMessage:
      '<31日間無料トライアル実施中>U-NEXTは映画見放題作品数No.1！不屈の名作から、心に残る感動作まで多数配信中。劇場公開から間もない最新映画もポイントを使ってお得に楽しめます。観たかった映画を今すぐ高画質でご視聴いただけます。',
    description: 'Genre YOUGA description',
  },
  HOUGA: {
    id: 'genre.hougaDescription',
    defaultMessage:
      '<31日間無料トライアル実施中>U-NEXTは映画見放題作品数No.1！不屈の名作から、心に残る感動作まで多数配信中。劇場公開から間もない最新映画もポイントを使ってお得に楽しめます。観たかった映画を今すぐ高画質でご視聴いただけます。',
    description: 'Genre HOUGA description',
  },
  FDRAMA: {
    id: 'genre.fdramaDescription',
    defaultMessage:
      '<31日間無料トライアル実施中>海外ドラマ観るならU-NEXT。日本初上陸の最新ドラマやU-NEXT独占配信作品など、まとめて一気に高画質でご視聴いただけます。',
    description: 'Genre FDRAMA description',
  },
  ADRAMA: {
    id: 'genre.adramaDescription',
    defaultMessage:
      '<31日間無料トライアル実施中>U-NEXTは韓流ドラマ見放題作品数No.1！日本初上陸の最新ドラマやU-NEXT独占配信作品など、まとめて一気に高画質でご視聴いただけます。',
    description: 'Genre ADRAMA description',
  },
  DRAMA: {
    id: 'genre.dramaDescription',
    defaultMessage:
      '<31日間無料トライアル実施中>日本最大級 国内ドラマ1,000作品以上が見放題！テレビ放送中の人気ドラマから懐かしの名作ドラマまで、まとめて一気に高画質でご視聴いただけます。',
    description: 'Genre DRAMA description',
  },
  ANIME: {
    id: 'genre.animeDescription',
    defaultMessage:
      '<31日間無料トライアル実施中>U-NEXTはアニメ見放題作品数No.1！テレビ放送中の人気アニメから懐かしの名作アニメまで、まとめて一気に高画質でご視聴いただけます。',
    description: 'Genre ANIME description',
  },
  KIDS: {
    id: 'genre.kidsDescription',
    defaultMessage:
      '<31日間無料トライアル実施中>日本最大級 900作品以上のキッズ番組を配信中！視聴率の高いランキング上位の人気番組から、ちょっとニッチなものや大人も楽しめる番組まで、まとめて一気に高画質でご視聴いただけます。',
    description: 'Genre KIDS description',
  },
  VARIETY: {
    id: 'genre.varietyDescription',
    defaultMessage:
      '<31日間無料トライアル実施中>人気のバラエティ/お笑い番組から、釣り/麻雀などマニアックな番組まで、まとめて一気に高画質でご視聴いただけます。',
    description: 'Genre VARIETY description',
  },
  DOCUMENT: {
    id: 'genre.documentDescription',
    defaultMessage:
      '<31日間無料トライアル実施中>日本最大級 800作品以上の報道・スペシャル動画を配信中！ビジネスに役立つ経済情報、人気ドキュメンタリー、NHKオンデマンドやテレ東BIZの作品をまとめて一気に高画質でご視聴いただけます。',
    description: 'Genre DOCUMENT description',
  },
  MUSIC_IDOL: {
    id: 'genre.musicIdolDescription',
    defaultMessage:
      '<31日間無料トライアル実施中>LIVE映像/舞台/ミュージックビデオ/人気アイドル動画まで、まとめて一気に高画質でご視聴いただけます。',
    description: 'Genre MUSIC_IDOL description',
  },
  STAGE_PLAY: {
    id: 'genre.stagePlayDescription',
    defaultMessage:
      '<31日間無料トライアル実施中>人気の演劇・ミュージカルから、2.5次元舞台まで、まとめて一気に高画質でご視聴いただけます。',
    description: 'Genre STAGE_PLAY description',
  },
  SOCCER: {
    id: 'genre.soccerDescription',
    defaultMessage:
      '<31日間無料トライアル実施中>サッカーの試合/ハイライト映像から、ドキュメンタリー番組まで、まとめて一気に高画質でご視聴いただけます。',
    description: 'Genre SOCCER description',
  },
  GOLF: {
    id: 'genre.golfDescription',
    defaultMessage:
      '<31日間無料トライアル実施中>ゴルフの試合/ハイライト映像から、ドキュメンタリー番組まで、まとめて一気に高画質でご視聴いただけます。',
    description: 'Genre GOLF description',
  },
  MARTIAL: {
    id: 'genre.martialDescription',
    defaultMessage:
      '<31日間無料トライアル実施中>格闘技の試合/ハイライト映像から、ドキュメンタリー番組まで、まとめて一気に高画質でご視聴いただけます。',
    description: 'Genre MARTIAL description',
  },
  OTH_SPORTS: {
    id: 'genre.othSportsDescription',
    defaultMessage:
      '<31日間無料トライアル実施中>試合/ハイライト映像から、ドキュメンタリー番組まで、まとめて一気に高画質でご視聴いただけます。',
    description: 'Genre OTH_SPORTS description',
  },
  ADULT: {
    id: 'genre.adultDescription',
    defaultMessage:
      '<31日間無料トライアル実施中>人気のグラビア / 成人映画から、お色気バラエティまで、まとめて一気に高画質でご視聴いただけます。',
    description: 'Genre ADULT description',
  },
  defaultKeywords: {
    id: 'genre.defaultKeywords',
    defaultMessage: '{genreDisplayLabel},動画,特集,おすすめ,名作,新作',
    description: 'Genre default keywords',
  },
});

export default messages;
