import React from 'react';
import AkamaiImage from '../../../Common/AkamaiImage';
import Container from './Container';
import PlayIcon from './PlayIcon';

interface Props {
  thumbnailUrl: string;
  thumbnailAlt: string;
}

const VideoThumbnail: React.FC<Props> = ({ thumbnailUrl, thumbnailAlt }) => {
  const type = 'video';
  const thumbnailImageConfig = {
    SD: {
      width: 256,
    },
    MOBILE_WIDE: {
      width: 224,
    },
  };

  return (
    <Container type={type}>
      <AkamaiImage
        src={thumbnailUrl}
        alt={thumbnailAlt}
        availableSizes={[224, 448]}
        sizeConfig={thumbnailImageConfig}
      />
      <PlayIcon type={type} />
    </Container>
  );
};

export default VideoThumbnail;
