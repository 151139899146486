import React from 'react';
import styled from 'styled-components';
import AkamaiImage from '../../../Common/AkamaiImage';
import { DailyFreeBadge } from '../../../Common/Badge/DaillyFreeBadge';
import Container from './Container';
import PlayIcon from './PlayIcon';

const StyledDailyFreeBadge = styled(DailyFreeBadge)`
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 140;
`;

interface Props {
  thumbnailUrl: string;
  thumbnailAlt: string;
  isDailyFree: boolean;
  isDailyFreePlus: boolean;
}

const BookThumbnail: React.FC<Props> = (props) => {
  const { thumbnailUrl, thumbnailAlt, isDailyFree, isDailyFreePlus } = props;
  const type = 'book';
  const thumbnailImageConfig = {
    SD: {
      width: 140,
    },
    MOBILE_WIDE: {
      width: 120,
    },
  };

  return (
    <Container type={type}>
      {(isDailyFree || isDailyFreePlus) && (
        <StyledDailyFreeBadge
          badgeType={isDailyFreePlus ? 'plus' : 'normal'}
          badgeSize="small"
        />
      )}
      <AkamaiImage
        src={thumbnailUrl}
        alt={thumbnailAlt}
        availableSizes={[120, 250]}
        sizeConfig={thumbnailImageConfig}
        letterbox={'5:7'}
      />
      <PlayIcon type={type} />
    </Container>
  );
};

export default BookThumbnail;
