import React, { ButtonHTMLAttributes, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { assert } from '../../../../utils/assert';
import { DEVICE } from '../../../styles';

export type ButtonType =
  | 'standard'
  | 'accent'
  | 'attention'
  | 'leanbackWelcome'
  | 'standardGray';

interface ButtonStyle {
  background: string;
  text: string;
  backgroundHover: string;
  textDisabled: string;
  backgroundDisabled: string;
}

function getButtonStyle(
  bag: Record<ButtonType, ButtonStyle>,
  type: ButtonType | undefined
): ButtonStyle {
  assert(!!type);
  const p = bag[type];
  return p;
}

type ContainerProps = PropsWithChildren<{
  buttonType: ButtonType;
}>;
interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    ContainerProps {}

const Button = styled.button<ButtonProps>`
  border: 0;
  padding: 10px 15px;
  width: 360px;
  height: 48px;
  border-radius: 2px;
  cursor: pointer;
  font-size: 14px;
  outline: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: ${({ theme, buttonType }) =>
    getButtonStyle(theme.button, buttonType).background};
  color: ${({ theme, buttonType }) =>
    getButtonStyle(theme.button, buttonType).text};
  transition: background-color 0.15s ease-in;

  &:hover,
  &:focus {
    background-color: ${({ theme, buttonType }) =>
      getButtonStyle(theme.button, buttonType).backgroundHover};
  }

  &:disabled {
    color: ${({ theme, buttonType }) =>
      getButtonStyle(theme.button, buttonType).textDisabled};
    background-color: ${({ theme, buttonType }) =>
      getButtonStyle(theme.button, buttonType).backgroundDisabled};
    cursor: not-allowed;
  }

  @media ${DEVICE.mobileWide} {
    height: 44px;
  }
`;

const Container = styled.div<ContainerProps>`
  text-align: center;
  box-sizing: border-box;
  border: 0;
  padding: 10px 15px;
  width: 360px;
  height: 48px;
  border-radius: 2px;
  cursor: pointer;
  font-size: 14px;
  line-height: 2;
  outline: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: ${({ theme, buttonType }) =>
    getButtonStyle(theme.button, buttonType).background};
  color: ${({ theme, buttonType }) =>
    getButtonStyle(theme.button, buttonType).text};
  transition: background-color 0.15s ease-in;

  &:hover {
    background-color: ${({ theme, buttonType }) =>
      getButtonStyle(theme.button, buttonType).backgroundHover};
  }

  @media ${DEVICE.mobileWide} {
    height: 44px;
  }
`;

const ButtonComponent: React.FC<ButtonProps> = ({
  children,
  buttonType = 'standard',
  ...buttonProps
}) => (
  <Button {...buttonProps} buttonType={buttonType}>
    {children}
  </Button>
);

const DivComponent: React.FC<ContainerProps> = ({
  children,
  buttonType = 'standard',
  ...props
}) => (
  <Container buttonType={buttonType} {...props}>
    {children}
  </Container>
);

export {
  ButtonComponent as LegacyButton,
  DivComponent as LegacyNonStandardButton,
};
