import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useIntl } from 'react-intl';
import { animated, useTransition } from 'react-spring';
import styled, { css } from 'styled-components';
import { adultBannerMessages } from '.';
import Icon, { Icons } from '../../../shared/components/Common/Icon';
import { MAIN_CONTENT_FOOTER_ID } from '../../../shared/components/Layout';
import useElementById from '../../../shared/hooks/useElementById';
import { DEVICE } from '../../../shared/styles';
import { COLORS } from '../../../shared/styles/theme';

const FLOATING_BUTTON_TRIGGER_HEIGHT = 300;

const FloatingButtonLink = styled(animated.button)<{ disabled: boolean }>`
  align-self: flex-end;
  margin-right: 16px;
  color: ${COLORS.white};
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `};

  @media ${DEVICE.mobileWide} {
    margin-right: 0;
    align-self: center;
  }
`;

const FloatingButton = styled.div`
  display: flex;
  height: 56px;
  padding: 0px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;

  border-radius: 28px;
  border: ${COLORS.white_20};
  background: ${COLORS.pink};
  box-shadow: 2px 4px 32px 0px ${COLORS.midnight};
  transition: background 0.15s ease-in;

  &:hover {
    background: ${COLORS.pink_light};
  }

  @media ${DEVICE.mobileWide} {
    align-items: center;
    width: 240px;
  }
`;

const FloatingButtonIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  opacity: 0.8;
`;

const FloatingButtonText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;

  font-size: 14px;
  font-weight: 600;
`;

const FloatingButtonSubText = styled.div`
  color: ${COLORS.white_75};
  text-align: center;
  font-size: 10px;
`;

const AdultFloatingButton: React.FC<{
  onClick: () => void;
  disabled: boolean;
}> = ({ onClick, disabled }) => {
  const intl = useIntl();
  const localShow = useRef<boolean>(false);
  const [showFloatingButton, setShowFloatingButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = Math.max(
        window.scrollY,
        document.documentElement.scrollTop,
        document.body.scrollTop
      );

      if (scrollTop >= FLOATING_BUTTON_TRIGGER_HEIGHT && !localShow.current) {
        localShow.current = true;
        setShowFloatingButton(true);
      }

      if (scrollTop < FLOATING_BUTTON_TRIGGER_HEIGHT && localShow.current) {
        localShow.current = false;
        setShowFloatingButton(false);
      }
    };

    handleScroll();

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const floatingButtonTransition = useTransition(showFloatingButton, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 150 },
  });
  const footerContainer = useElementById(MAIN_CONTENT_FOOTER_ID);

  return floatingButtonTransition(
    (props, item) =>
      item &&
      footerContainer &&
      ReactDOM.createPortal(
        <FloatingButtonLink style={props} onClick={onClick} disabled={disabled}>
          <FloatingButton>
            <FloatingButtonText>
              <div>
                {intl.formatMessage(adultBannerMessages.adultBannerText)}
              </div>
              <FloatingButtonIcon name={Icons.NEW_TAB} />
            </FloatingButtonText>
            <FloatingButtonSubText>
              {intl.formatMessage(adultBannerMessages.overAgeOnly)}
            </FloatingButtonSubText>
          </FloatingButton>
        </FloatingButtonLink>,
        footerContainer
      )
  );
};

export default AdultFloatingButton;
