import styled from 'styled-components';
import { ResumeCardType } from '..';
import { COLORS } from '../../../../styles/theme';
import Icon, { Icons } from '../../../Common/Icon';
import Container from './Container';

const PlayIconContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;

  display: flex;
  width: 48px;
  height: 48px;
  margin: auto;
  justify-content: center;
  border-radius: 50%;
  box-sizing: border-box;
  transition: all 0.15s ease-in;
  border: 1px solid transparent;
  background-color: ${COLORS.charcoal_overlay_50};

  > div {
    width: 28px;
  }

  ${Container}:hover &,
  ${Container}:focus & {
    color: ${COLORS.black};
    border: 1px solid ${COLORS.black};
    background-color: ${COLORS.white};
  }
`;

const PlayIcon = ({ type }: { type: ResumeCardType }) => (
  <PlayIconContainer>
    <Icon name={type === 'video' ? Icons.PLAY : Icons.READ} />
  </PlayIconContainer>
);

export default PlayIcon;
