import { useMutation } from '@apollo/client';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import {
  BookVolumeGroupTypeCode,
  cosmo_getUserBlocksQuery,
  cosmo_getUserBlocksQueryVariables,
  cosmo_moreOptions_removeVideoHistoryMutation,
  cosmo_moreOptions_removeVideoHistoryMutationVariables,
  cosmo_removeBookHistoryMutation,
  cosmo_removeBookHistoryMutationVariables,
  PortalSortOrder,
} from '../../../__generated__/globalTypes';
import Modal from '../../../shared/components/Modal';
import { isPoint } from '../../../shared/components/PointPriceBadge';
import Slider from '../../../shared/components/Slider';
import { ExpandableBookCard } from '../../../shared/components/TitleCard/Book';
import ReadMoreCard from '../../../shared/components/TitleCard/ReadMore';
import ResumeCard from '../../../shared/components/TitleCard/Resume';
import { ExpandableVideoCard } from '../../../shared/components/TitleCard/Video';
import { FocusStyledTitleLink } from '../../../shared/components/TitleLink';
import { UrlQueryParams } from '../../../shared/constants';
import { globalMessages } from '../../../shared/constants/messages';
import { createExtendedApolloError } from '../../../shared/ExtendedApolloError';
import useClientQuery from '../../../shared/hooks/useClientQuery';
import { DEVICE } from '../../../shared/styles';
import {
  extractGraphQLError,
  handleOnlyOnEnter,
  mapNumberToWeekdayKey,
} from '../../../utils';
import { mergeQueryString } from '../../../utils/routeHelper';
import type { HomeBookHistoryBlockMenuLog } from '../../Log/__types__/home-bookHistoryBlock-menu';
import type { HomeBookHistoryBlockMoreLog } from '../../Log/__types__/home-bookHistoryBlock-more';
import type { HomeBookHistoryBlockTitleCardLog } from '../../Log/__types__/home-bookHistoryBlock-titleCard';
import type { HomeBookHistoryBlockTitleDetailLog } from '../../Log/__types__/home-bookHistoryBlock-titleDetail';
import type { HomeBookRecommendBlockMoreLog } from '../../Log/__types__/home-bookRecommendBlock-more';
import type { HomeBookRecommendBlockTitleCardLog } from '../../Log/__types__/home-bookRecommendBlock-titleCard';
import type { HomeVideoHistoryBlockMenuLog } from '../../Log/__types__/home-videoHistoryBlock-menu';
import type { HomeVideoHistoryBlockTitleCardLog } from '../../Log/__types__/home-videoHistoryBlock-titleCard';
import type { HomeVideoHistoryBlockTitleDetailLog } from '../../Log/__types__/home-videoHistoryBlock-titleDetail';
import type { HomeVideoMylistBlockMoreLog } from '../../Log/__types__/home-videoMylistBlock-more';
import type { HomeVideoMylistBlockTitleCardLog } from '../../Log/__types__/home-videoMylistBlock-titleCard';
import { getKafkaClient } from '../../Log/kafkaClient';
import { REMOVE_BOOK_HISTORY } from '../../MyList/gql';
import { PLAYBACK_STATUS_FLAG } from '../../videoPlayer/constants';
import BlockHeading from '../BlockHeading';
import BlockTitle from '../BlockTitle';
import {
  BLOCKS_MARGIN,
  BLOCKS_MARGIN_MOBILE,
  TITLES_PER_BLOCK,
} from '../constants';
import { GET_USER_BLOCKS } from '../gql';
import SliderPlaceholder from '../SliderPlaceholder';
import { isActive } from '../utils';
import { REMOVE_VIDEO_HISTORY } from './gql';

const messages = defineMessages({
  videoHistory: {
    id: 'genre.userblocks.videoHistory',
    defaultMessage: 'つづきを再生',
  },
  bookHistory: {
    id: 'genre.userblocks.bookhistory',
    defaultMessage: 'つづきを読む',
  },
  mylist: {
    id: 'genre.userblocks.mylist',
    defaultMessage: 'マイリスト',
  },
  openTitleDetail: {
    id: 'genre.userblocks.moreOptions.openTitleDetail',
    defaultMessage: '作品詳細を見る',
  },
  deleteFromList: {
    id: 'genre.userblocks.moreOptions.deleteFromList',
    defaultMessage: '視聴履歴から削除',
  },
  errorDeleteFromList: {
    id: 'genre.userblocks.moreOptions.errorDeleteFromList',
    defaultMessage: '視聴履歴から削除できませんでした。',
  },
  close: {
    id: 'genre.userblocks.moreOptions.close',
    defaultMessage: '閉じる',
  },
  errorTitle: {
    id: 'genre.userblocks.moreOptions.errorTitle',
    defaultMessage: 'エラー',
  },
});

const UserBlocksContainer = styled.div`
  position: relative;
  z-index: 50;
`;

const UserBlock = styled.div`
  margin-bottom: ${BLOCKS_MARGIN}px;
  @media ${DEVICE.mobileWide} {
    margin-bottom: ${BLOCKS_MARGIN_MOBILE}px;
  }
`;

const SLIDER_Z_INDEX_BASE = 20;

const userBlocksQueryVariable: cosmo_getUserBlocksQueryVariables = {
  favoriteTitlesSortOrder: PortalSortOrder.LAST_UPDATE_DESC,
  titlesPerBlock: TITLES_PER_BLOCK,

  volumeGroupType: BookVolumeGroupTypeCode.ALL,
};

const UserBlocks: React.FC = () => {
  const { data, error } = useClientQuery<
    cosmo_getUserBlocksQuery,
    cosmo_getUserBlocksQueryVariables
  >(GET_USER_BLOCKS, {
    variables: userBlocksQueryVariable,
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore',
  });

  const router = useRouter();

  const intl = useIntl();
  const [showHistoryDeletionAlert, setShowHistoryDeletionAlert] =
    useState(false);

  const goToTitleDetail = (titleCode: string) => {
    const newRoute = {
      ...mergeQueryString(router, {
        [UrlQueryParams.TITLE_DETAIL_ID]: titleCode,
      }),
    };
    router.push(newRoute.pathname, newRoute.path, {
      shallow: true,
    });
  };
  const goToBookTitleDetail = (
    bookSakuhin: string,
    bookCode: string,
    bookViewCode: string,
    featurePieceCode?: string
  ) => {
    const newRoute = {
      ...mergeQueryString(router, {
        [UrlQueryParams.BOOK_TITLE_DETAIL_ID]: bookSakuhin,
        [UrlQueryParams.BOOK_VIEW_CODE]: bookViewCode,
        [UrlQueryParams.FEATURE_PIECE_CODE]: featurePieceCode ?? undefined,
        ...(bookViewCode === 'BOOK'
          ? {
              [UrlQueryParams.BOOK_CODE]: bookCode,
            }
          : {}),
      }),
    };
    router.push(newRoute.pathname, newRoute.path, {
      shallow: true,
    });
  };

  let toBeRemovedEpisodeCode = '';

  const [removeVideoHistory] = useMutation<
    cosmo_moreOptions_removeVideoHistoryMutation,
    cosmo_moreOptions_removeVideoHistoryMutationVariables
  >(REMOVE_VIDEO_HISTORY, {
    update(cache, { data }) {
      if (!data?.removeVideoHistory.removed) {
        setShowHistoryDeletionAlert(true);
        return;
      }
      const userBlocksQuery = cache.readQuery<
        cosmo_getUserBlocksQuery,
        cosmo_getUserBlocksQueryVariables
      >({
        query: GET_USER_BLOCKS,
        variables: userBlocksQueryVariable,
      });
      const watchingEpisodes = userBlocksQuery?.webfront_watchingEpisodes;

      const newWatchingEpisodes = watchingEpisodes
        ? watchingEpisodes.filter(
            (episode) => episode.id !== toBeRemovedEpisodeCode
          )
        : null;

      cache.writeQuery<
        cosmo_getUserBlocksQuery,
        cosmo_getUserBlocksQueryVariables
      >({
        query: GET_USER_BLOCKS,
        variables: userBlocksQueryVariable,
        data: {
          webfront_watchingEpisodes: newWatchingEpisodes,
          webfront_favoriteTitles:
            userBlocksQuery?.webfront_favoriteTitles ?? null,
          bookHistory: userBlocksQuery?.bookHistory ?? null,
          recommendedBooks: userBlocksQuery?.recommendedBooks ?? null,
        },
      });
    },
    onError: () => setShowHistoryDeletionAlert(true),
  });

  const [removeBookHistory] = useMutation<
    cosmo_removeBookHistoryMutation,
    cosmo_removeBookHistoryMutationVariables
  >(REMOVE_BOOK_HISTORY, {
    update(cache, { data }) {
      if (!data?.removeBookHistory.bookSakuhinCodes) {
        setShowHistoryDeletionAlert(true);
        return;
      }
      const userBlocksQuery = cache.readQuery<
        cosmo_getUserBlocksQuery,
        cosmo_getUserBlocksQueryVariables
      >({
        query: GET_USER_BLOCKS,
        variables: userBlocksQueryVariable,
      });
      const bookTitles = userBlocksQuery?.bookHistory?.books;

      const remainingBookTitles = bookTitles
        ? bookTitles.filter(
            (book) =>
              book.sakuhinCode !== data?.removeBookHistory.bookSakuhinCodes[0]
          )
        : null;

      cache.writeQuery<
        cosmo_getUserBlocksQuery,
        cosmo_getUserBlocksQueryVariables
      >({
        query: GET_USER_BLOCKS,
        variables: userBlocksQueryVariable,
        data: {
          webfront_watchingEpisodes:
            userBlocksQuery?.webfront_watchingEpisodes ?? null,
          webfront_favoriteTitles:
            userBlocksQuery?.webfront_favoriteTitles ?? null,
          recommendedBooks: userBlocksQuery?.recommendedBooks ?? null,
          bookHistory:
            remainingBookTitles && userBlocksQuery?.bookHistory?.__typename
              ? {
                  __typename: userBlocksQuery.bookHistory.__typename,
                  books: remainingBookTitles,
                }
              : null,
        },
      });
    },
    onError: () => setShowHistoryDeletionAlert(true),
  });

  if (error) {
    const [errorCode] = extractGraphQLError(
      [
        'WATCHING_EPISODES_ERROR',
        'VIDEO_FAVORITE_ERROR',
        'BOOK_HISTORY_ERROR',
        'USER_RECOMMEND_FEATURE_ERROR',
        'BOOK_USER_RECOMMENDED_ERROR',
      ],
      error
    );
    if (errorCode) {
      throw createExtendedApolloError(error, { errorCode });
    }

    throw error;
  }

  if (!data) {
    return (
      <>
        <SliderPlaceholder />
        <SliderPlaceholder />
        <SliderPlaceholder />
        <SliderPlaceholder />
        <SliderPlaceholder />
      </>
    );
  }

  const { recommendedBooks } = data;

  const bookRecommendTitleNum = recommendedBooks?.results;
  const bookRecommendTitleNumText = bookRecommendTitleNum
    ? intl.formatMessage(
        bookRecommendTitleNum < 50
          ? globalMessages.countNumber
          : globalMessages.countNumberOverFifty,
        { count: intl.formatNumber(bookRecommendTitleNum) }
      )
    : '';
  const bookRecommendDetailHref = '/book/recommend';

  return (
    <UserBlocksContainer>
      <Modal
        visible={showHistoryDeletionAlert}
        onOk={() => setShowHistoryDeletionAlert(false)}
        title={intl.formatMessage(messages.errorTitle)}
        okText={intl.formatMessage(messages.close)}
      >
        <FormattedMessage {...messages.errorDeleteFromList} />
      </Modal>
      {(data.webfront_watchingEpisodes ?? []).length > 0 && (
        <UserBlock data-ucn="resumeVideoBlock">
          <BlockHeading
            hasNoLead
            href="/mylist/history/video"
            title={intl.formatMessage(messages.videoHistory)}
          />
          <Slider
            type="resumeVideo"
            zIndex={SLIDER_Z_INDEX_BASE - 1}
            keyPrefix={`genre-slider-mylist`}
            items={({ activeRange }) => {
              if (!data.webfront_watchingEpisodes) {
                return [];
              }
              return data.webfront_watchingEpisodes.map((episode, index) => {
                if (!episode) {
                  return <div key={index} />;
                }
                const trackVideoHistoryClick = async () => {
                  await getKafkaClient().trackUserClickDimension1<HomeVideoHistoryBlockTitleCardLog>(
                    'home-videoHistoryBlock-titleCard',
                    {
                      index,
                      sakuhin_code: episode.episodeTitleInfo?.id ?? '',
                      episode_code: episode.id,
                    }
                  );
                };
                const progress =
                  episode.interruption && episode.duration
                    ? episode.interruption / episode.duration
                    : 0;
                return (
                  <ResumeCard
                    type="video"
                    key={`${episode.id}-mylist`}
                    title={episode.episodeTitleInfo?.name ?? ''}
                    thumbnailUrl={`//${episode.thumbnail?.standard}`}
                    thumbnailAlt={episode.episodeName ?? ''}
                    href={`/play/${episode.episodeTitleInfo?.id}/${episode.id}?${UrlQueryParams.PLAYBACK_STATUS_FLAG}=${PLAYBACK_STATUS_FLAG.CONTINUE_WATCHING}`}
                    isActive={isActive(index, activeRange)}
                    progressPercentage={
                      episode.completeFlag && progress === 0 ? 1 : progress
                    }
                    subtitle={episode.displayNo ?? undefined}
                    onClick={trackVideoHistoryClick}
                    onClickMoreOptionsButton={() => {
                      getKafkaClient().trackUserClickDimension1<HomeVideoHistoryBlockMenuLog>(
                        'home-videoHistoryBlock-menu',
                        {
                          index,
                          sakuhin_code: episode.episodeTitleInfo?.id ?? '',
                        }
                      );
                    }}
                    onClickOpenTitleDetail={() => {
                      getKafkaClient().trackUserClickDimension1<HomeVideoHistoryBlockTitleDetailLog>(
                        'home-videoHistoryBlock-titleDetail',
                        {
                          index,
                          sakuhin_code: episode.episodeTitleInfo?.id ?? '',
                          episode_code: episode.id,
                        }
                      );
                      goToTitleDetail(episode.episodeTitleInfo?.id ?? '');
                    }}
                    onClickRemoveFromHistory={() => {
                      toBeRemovedEpisodeCode = episode.id;
                      removeVideoHistory({
                        variables: {
                          titleId: episode.episodeTitleInfo?.id ?? '',
                        },
                      });
                    }}
                  />
                );
              });
            }}
          />
        </UserBlock>
      )}
      {data.bookHistory && data.bookHistory.books.length > 0 && (
        <UserBlock data-ucn="resumeBookBlock">
          <BlockHeading
            hasNoLead
            href="/mylist/history/book"
            title={intl.formatMessage(messages.bookHistory)}
            onClick={() =>
              getKafkaClient().trackUserClickDimension0<HomeBookHistoryBlockMoreLog>(
                'home-bookHistoryBlock-more',
                {}
              )
            }
          />
          <Slider
            type="resumeBook"
            zIndex={SLIDER_Z_INDEX_BASE - 2}
            keyPrefix={`genre-slider-bookhistory`}
            items={({ activeRange }) => {
              if (!data.bookHistory) {
                return [];
              }
              return data.bookHistory.books.map((book, index) => {
                if (!book.book) {
                  return <></>;
                }
                const trackBookHistoryHandler = async () => {
                  await getKafkaClient().trackUserClickDimension1<HomeBookHistoryBlockTitleCardLog>(
                    'home-bookHistoryBlock-titleCard',
                    {
                      index,
                      book_sakuhin_code: book.sakuhinCode,
                      book_code: book.book?.code ?? '',
                    }
                  );
                };
                const mainFile = book.book.bookContent?.mainBookFile;
                return (
                  <ResumeCard
                    type="book"
                    key={`${book.sakuhinCode}-mylist`}
                    title={book.book.name}
                    thumbnailUrl={`//${book.book.thumbnail.standard}`}
                    thumbnailAlt={book.book.name}
                    isDailyFree={!!book.isBookSakuhinTicketAvailable}
                    isDailyFreePlus={!!book.isBookPlusTicketAvailable}
                    topRightText={
                      book.hasUnreadChapter
                        ? intl.formatMessage(globalMessages.hasUnread)
                        : undefined
                    }
                    href={`/book/view/${book.sakuhinCode}/${book.book.code}`}
                    isActive={isActive(index, activeRange)}
                    progressPercentage={
                      mainFile?.completeFlg && mainFile?.resumePoint === 0
                        ? 1
                        : mainFile?.resumePoint ?? 0
                    }
                    onClick={trackBookHistoryHandler}
                    onClickMoreOptionsButton={() => {
                      getKafkaClient().trackUserClickDimension1<HomeBookHistoryBlockMenuLog>(
                        'home-bookHistoryBlock-menu',
                        {
                          index,
                          book_sakuhin_code: book.sakuhinCode,
                        }
                      );
                    }}
                    onClickOpenTitleDetail={() => {
                      getKafkaClient().trackUserClickDimension1<HomeBookHistoryBlockTitleDetailLog>(
                        'home-bookHistoryBlock-titleDetail',
                        {
                          index,
                          book_sakuhin_code: book.sakuhinCode,
                          book_code: book.book?.code ?? '',
                        }
                      );
                      goToBookTitleDetail(
                        book.sakuhinCode,
                        book.book?.code ?? '',
                        book.bookViewCode,
                        book.featurePieceCode ?? undefined
                      );
                    }}
                    onClickRemoveFromHistory={() => {
                      removeBookHistory({
                        variables: {
                          bookSakuhinCodes: [book.sakuhinCode],
                        },
                      });
                    }}
                  />
                );
              });
            }}
          />
        </UserBlock>
      )}
      {data.webfront_favoriteTitles &&
        data.webfront_favoriteTitles.titles.length > 0 && (
          <UserBlock data-ucn="favoriteVideoBlock">
            <BlockHeading
              hasNoLead
              href="/mylist/favorite/video"
              title={intl.formatMessage(messages.mylist)}
              numText={intl.formatMessage(
                data.webfront_favoriteTitles.pageInfo.results < 50
                  ? globalMessages.countNumber
                  : globalMessages.countNumberOverFifty,
                {
                  count: intl.formatNumber(
                    data.webfront_favoriteTitles.pageInfo.results
                  ),
                }
              )}
              onClick={() =>
                getKafkaClient().trackUserClickDimension0<HomeVideoMylistBlockMoreLog>(
                  'home-videoMylistBlock-more',
                  {}
                )
              }
            />
            <Slider
              type="video"
              zIndex={SLIDER_Z_INDEX_BASE - 3}
              keyPrefix={`genre-slider-mylist`}
              items={({ activeRange }) => {
                if (!data.webfront_favoriteTitles) {
                  return [];
                }
                return data.webfront_favoriteTitles.titles.map(
                  (title, index) => {
                    /**
                     * If we for some reason run into missing titles in this map we
                     * need to return an empty element. This should never happen so
                     * we are just keeping Typescript happy here
                     */
                    if (!title) {
                      return <div key={index} />;
                    }
                    const weekdayKey = mapNumberToWeekdayKey(
                      title.updateOfWeek ?? 0
                    );
                    const weekdayText = weekdayKey
                      ? intl.formatMessage(globalMessages[weekdayKey])
                      : '';
                    const updateWeekdayInfo =
                      weekdayText &&
                      intl.formatMessage(globalMessages.updateText, {
                        updateText: weekdayText,
                      });
                    const trackMyListClick = () => {
                      getKafkaClient().trackUserClickDimension1<HomeVideoMylistBlockTitleCardLog>(
                        'home-videoMylistBlock-titleCard',
                        {
                          index,
                          sakuhin_code: title.id,
                        }
                      );
                    };

                    return (
                      <FocusStyledTitleLink
                        key={`${title.id}-mylist`}
                        type="video"
                        data-ucn="favoriteVideoBlock-item-link"
                        titleCode={title.id}
                        tabIndex={isActive(index, activeRange) ? undefined : -1}
                        onKeyDown={(e) => {
                          handleOnlyOnEnter(e, trackMyListClick);
                        }}
                        onClick={trackMyListClick}
                      >
                        <ExpandableVideoCard
                          titleName={title.titleName ?? ''}
                          year={title.productionYear ?? ''}
                          rate={title.rate ?? 0}
                          thumbnailUrl={`//${title.thumbnail?.standard}`}
                          catchphrase={title.catchphrase ?? ''}
                          updateInfo={
                            updateWeekdayInfo && title.lastEpisode
                              ? `${updateWeekdayInfo} ${title.lastEpisode}`
                              : updateWeekdayInfo || title.lastEpisode || ''
                          }
                          nfreeBadge={title.nfreeBadge ?? undefined}
                          hasSub={title.hasSubtitle ?? false}
                          hasDub={title.hasDub ?? false}
                          isNew={title.isNew ?? false}
                          isPoint={isPoint(title)}
                          isOnlyOn={title.exclusive.isOnlyOn}
                          price={title.minimumPrice || undefined}
                          hasMultiprice={!!title.hasMultiprice}
                          isLazy
                        />
                      </FocusStyledTitleLink>
                    );
                  }
                );
              }}
            />
          </UserBlock>
        )}
      {recommendedBooks !== null && recommendedBooks.books.length > 0 && (
        <UserBlock data-ucn="recommendedBookBlock">
          <BlockHeading
            hasNoLead={!recommendedBooks.comment}
            href={bookRecommendDetailHref}
            title={recommendedBooks.heading}
            numText={bookRecommendTitleNumText}
            onClick={() => {
              getKafkaClient().trackUserClickDimension0<HomeBookRecommendBlockMoreLog>(
                'home-bookRecommendBlock-more',
                {}
              );
            }}
          />
          {recommendedBooks.comment && (
            <BlockTitle.Lead>{recommendedBooks.comment}</BlockTitle.Lead>
          )}
          <Slider
            type="book"
            keyPrefix="book-user-recommended"
            zIndex={SLIDER_Z_INDEX_BASE - 6}
            items={({ activeRange }) => {
              const titles = recommendedBooks.books.map((book, index) => {
                if (!book.book) {
                  return <></>;
                }
                let author = '';

                if (book.book.credits.length > 0) {
                  author =
                    book.book.credits.length > 1
                      ? `${book.book.credits[0].penName}...`
                      : book.book.credits[0].penName || '';
                }

                const bookMagazine = book.book.publishedIn.reduce(
                  (reduction, value) => {
                    const currentText = value.name || '';
                    return !reduction
                      ? currentText
                      : `${reduction} ${currentText}`;
                  },
                  ''
                );
                const isEpisodeSpecified = book.bookViewCode === 'BOOK';

                return (
                  <FocusStyledTitleLink
                    data-ucn="recommendedBookBlock-item-link"
                    onClick={() => {
                      getKafkaClient().trackUserClickDimension1<HomeBookRecommendBlockTitleCardLog>(
                        'home-bookRecommendBlock-titleCard',
                        {
                          index,
                          book_sakuhin_code: book.sakuhinCode,
                          book_code: book.book?.code ?? '',
                        }
                      );
                    }}
                    type="book"
                    titleCode={book.sakuhinCode}
                    optionalCode={{
                      bookCode: book.book.code,
                      bookViewCode: book.bookViewCode,
                      featurePieceCode: book.featurePieceCode ?? undefined,
                    }}
                    key={`key-${book.book.code}-${index}`}
                    tabIndex={isActive(index, activeRange) ? undefined : -1}
                  >
                    <ExpandableBookCard
                      bookType={book.book.mediaType.code}
                      titleName={
                        isEpisodeSpecified ? book.book.name : book.name
                      }
                      thumbnailUrl={`//${book.book.thumbnail.standard}`}
                      isNew={!!book.isNew}
                      isPreorderable={!!book.book.isPreorderable}
                      numberOfFree={
                        book.freeBookNum
                          ? intl.formatMessage(
                              book.isChapter
                                ? globalMessages.freeChapterNum
                                : globalMessages.freeBookNum,
                              {
                                freeBookNum: book.freeBookNum,
                              }
                            )
                          : ''
                      }
                      publicStartDateTime={
                        book.book.publicStartDateTime
                          ? intl.formatMessage(globalMessages.delivery, {
                              date: intl.formatDate(
                                book.book.publicStartDateTime,
                                {
                                  month: 'long',
                                  day: 'numeric',
                                }
                              ),
                            })
                          : ''
                      }
                      rate={book.rate}
                      isOnSale={!!book.isSale}
                      isYomihodai={book.paymentBadgeList.some(
                        (b) => b.code === 'SVOD'
                      )}
                      publisher={book.book.publisher?.name ?? ''}
                      author={author}
                      minimumPrice={book.minPrice}
                      hasMultiPrice={book.hasMultiplePrices}
                      totalBookNum={book.totalBookNum}
                      bookMagazine={bookMagazine}
                      subgenreTagList={book.subgenreTagList}
                      isDailyFree={!!book.isBookSakuhinTicketAvailable}
                      isDailyFreePlus={!!book.isBookPlusTicketAvailable}
                      isChapter={!!book.isChapter}
                      isEpisodeSpecified={isEpisodeSpecified}
                    />
                  </FocusStyledTitleLink>
                );
              });

              if (
                typeof bookRecommendTitleNum === 'number' &&
                bookRecommendTitleNum > TITLES_PER_BLOCK
              ) {
                titles.push(
                  <Link href={bookRecommendDetailHref}>
                    <ReadMoreCard
                      titleNumText={bookRecommendTitleNumText}
                      type="book"
                    />
                  </Link>
                );
              }

              return titles;
            }}
          />
        </UserBlock>
      )}
    </UserBlocksContainer>
  );
};

export default UserBlocks;
