import debounce from 'just-debounce-it';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import useClickOutside from '../../../hooks/useClickOutside';
import useMedia from '../../../hooks/useMedia';
import { DEVICE } from '../../../styles';
import LegacyIcon, { LegacyIcons } from '../../Common/Icon/LegacyIcon';
import MoreOptionOverflowMenu, {
  MORE_OPTION_DROPDOWN_WIDTH,
  MORE_OPTION_DROPDOWN_WIDTH_MOBILE,
} from './MoreOptionOverFlowMenu';

const ResumeCardStageContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding: 8px 8px 12px 12px;
  align-items: center;

  @media ${DEVICE.mobileWide} {
    padding: 8px 8px 8px 10px;
  }
`;

const ResumeCardStageTextContainer = styled.div`
  font-weight: 300;
  margin-right: 8px;
  height: 34px;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;

  @media ${DEVICE.mobileWide} {
    height: 32px;
  }
`;

const ResumeCardStageTitle = styled.div<{ hasSubTitle: boolean }>`
  color: ${({ theme }) => theme.text.standard};
  font-size: 14px;
  line-height: 130%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${({ hasSubTitle }) => (hasSubTitle ? 1 : 2)};
  overflow: hidden;
  white-space: normal;

  @media ${DEVICE.mobileWide} {
    font-size: 12px;
  }
`;

const ResumeCardStageSubTitle = styled.div`
  color: ${({ theme }) => theme.text.linkHover};
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @media ${DEVICE.mobileWide} {
    font-size: 10px;
  }
`;

const MoreOptionButton = styled.button`
  border-radius: 50%;
  width: 28px;
  height: 28px;
  background: transparent;
  padding: 0;
  border: none;
  margin-left: auto;
  cursor: pointer;
  flex-shrink: 0;

  @media ${DEVICE.mobileWide} {
    width: 24px;
    height: 24px;
  }

  :hover {
    background-color: ${({ theme }) =>
      theme.button.standard.backgroundDisabled};
  }

  :focus {
    background-color: ${({ theme }) =>
      theme.button.standard.backgroundDisabled};
    outline: none;
  }

  > div {
    height: 100%;
    min-width: 12px;
    > svg {
      top: 0;
    }
  }
`;

interface ResumeCardStageProps {
  titleText: string;
  subTitleText?: string;
  items: ReactNode[];
  onClickMoreOptionsButton?: () => void;
}

const ResumeCardStage: React.FC<ResumeCardStageProps> = ({
  titleText,
  subTitleText,
  items,
  onClickMoreOptionsButton,
}) => {
  const popperRef = useRef<HTMLButtonElement>(null);
  const overflowMenuRef = useRef<HTMLDivElement>(null);

  const [show, setShow] = useState<boolean>(false);

  const [clientWidth, setClientWidth] = useState<number>(
    typeof document !== 'undefined' ? document.documentElement.clientWidth : 0
  );

  const [popupLeft, setPopupLeft] = useState<string | undefined>(undefined);

  const dropdownWidth = useMedia(
    [DEVICE.mobile, DEVICE.exceptMobile],
    [MORE_OPTION_DROPDOWN_WIDTH_MOBILE, MORE_OPTION_DROPDOWN_WIDTH],
    MORE_OPTION_DROPDOWN_WIDTH
  );

  const rightMarginForSliderButton = useMedia(
    [DEVICE.mobile, DEVICE.exceptMobile],
    [40, 48],
    48
  );

  const setDropdownPosition = React.useMemo(
    () =>
      debounce(
        () => {
          const popperRect = popperRef.current?.getBoundingClientRect();
          const left = popperRect?.left ?? 0;
          const right = popperRect?.right ?? 0;
          const offsetParent = popperRef.current?.offsetParent;
          const offsetParentLeft =
            offsetParent?.getBoundingClientRect().left ?? 0;

          const centerX = (left + right) / 2;
          const minMargin = dropdownWidth / 2; // 左右に必要なスペース
          const minMarginRight = minMargin + rightMarginForSliderButton;

          let offsetX = centerX - dropdownWidth / 2 - offsetParentLeft;
          if (centerX < minMargin) {
            // have not enough space for left side
            offsetX = 0;
          } else if (clientWidth - centerX < minMarginRight) {
            // have not enough space for right side
            const insufficientX = minMarginRight - (clientWidth - centerX);
            offsetX = offsetX - insufficientX;
          }

          setPopupLeft(`${offsetX}px`);
        },
        500,
        true
      ),
    [dropdownWidth, rightMarginForSliderButton, clientWidth]
  );

  const updateClientWidth = () => {
    if (typeof document !== 'undefined')
      setClientWidth(document.documentElement.clientWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateClientWidth);
    return () => window.removeEventListener('resize', updateClientWidth);
  }, []);

  useEffect(() => {
    if (!show) return;
    setDropdownPosition();
  }, [clientWidth, show, dropdownWidth, setDropdownPosition]);

  useEffect(() => {
    setShow(false);
  }, [clientWidth]);

  const closePopupCallback = useCallback(() => setShow(false), []);

  useClickOutside([popperRef, overflowMenuRef], closePopupCallback);

  return (
    <section>
      <ResumeCardStageContainer>
        <ResumeCardStageTextContainer>
          <ResumeCardStageTitle hasSubTitle={!!subTitleText}>
            {titleText}
          </ResumeCardStageTitle>
          {!!subTitleText && (
            <ResumeCardStageSubTitle>{subTitleText}</ResumeCardStageSubTitle>
          )}
        </ResumeCardStageTextContainer>
        <MoreOptionButton
          style={{
            zIndex: 5000,
          }}
          onClick={() => {
            setShow((prev) => !prev);
            if (typeof onClickMoreOptionsButton === 'function') {
              onClickMoreOptionsButton();
            }
          }}
          ref={popperRef}
          data-ucn="resumeCard-menu-btn"
        >
          <LegacyIcon name={LegacyIcons.MORE_VERTICAL} />
        </MoreOptionButton>
      </ResumeCardStageContainer>
      {show && popupLeft && (
        <MoreOptionOverflowMenu
          left={popupLeft}
          closeCallback={closePopupCallback}
          items={items}
          overflowMenuRef={overflowMenuRef}
        />
      )}
    </section>
  );
};

export default ResumeCardStage;
