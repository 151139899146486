import { gql } from '@apollo/client';

export const GET_ONE_TIME_TOKEN = gql`
  query cosmo_getOneTimeToken {
    webfront_oneTimeToken {
      token
    }
  }
`;

export const PURCHASE_ADULT_RIGHTS = gql`
  mutation cosmo_purchaseAdultRights {
    purchaseAdultRights {
      isPurchased
    }
  }
`;
