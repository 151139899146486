import { useEffect } from 'react';

/**
 * useClickOutside
 *
 * Simple hook to add an event listener to the body and allow a callback to
 * be triggered when clicking outside of the target ref
 *
 * @param refList List of HTML Element ref objects to check if click is outside of
 * @param callback Callback triggered when clicking outside of ref element
 */
const useClickOutside = (
  refList: React.MutableRefObject<HTMLElement | HTMLDivElement | null>[],
  callback: (e: MouseEvent) => void
): void => {
  useEffect(() => {
    const handleBodyClick = (e: MouseEvent) => {
      const isOutSide = refList.every((ref) => {
        return ref.current && !ref.current.contains(e.target as Node);
      });
      isOutSide && callback(e);
    };
    document.body.addEventListener('click', handleBodyClick);

    return () => {
      document.body.removeEventListener('click', handleBodyClick);
    };
  }, [refList, callback]);
};

export default useClickOutside;
