import { useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import React, { useCallback, useRef, useState, type ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import {
  cosmo_getOneTimeTokenQuery,
  cosmo_purchaseAdultRightsMutation,
  cosmo_purchaseAdultRightsMutationVariables,
} from '../../../__generated__/globalTypes';
import R18Icon from '../../../assets/icons/global-icon-r-18.svg';
import { LegacyNonStandardButton } from '../../../shared/components/Common/Button/Legacy';
import Modal from '../../../shared/components/Modal';
import { GlobalConfig } from '../../../shared/constants';
import useClientQuery from '../../../shared/hooks/useClientQuery';
import useLoginUrl from '../../../shared/hooks/useLoginUrl';
import { useUserInfo } from '../../../shared/hooks/useUserInfo';
import { DEVICE } from '../../../shared/styles';
import { COLORS } from '../../../shared/styles/theme';
import { VideoCategoryList } from '../GenreBanner/CategoryList';
import AdultFloatingButton from './AdultFloatingButton';
import { GET_ONE_TIME_TOKEN, PURCHASE_ADULT_RIGHTS } from './gql';

const ARTWORK_URL_PC =
  'metac.nxtv.jp/img/static_resource/img/hnextlp/eyecatch/pc_home.png';
const ARTWORK_URL_SP =
  'metac.nxtv.jp/img/static_resource/img/hnextlp/eyecatch/sp_home.png';

export const adultBannerMessages = defineMessages({
  adultBannerCaution: {
    id: 'adultBanner.caution',
    defaultMessage:
      '成人向けコンテンツを含んでおりますのでご注意ください。H-NEXTは18歳未満の方はご遠慮願います。',
  },
  adultBannerText: {
    id: 'adultBanner.text',
    defaultMessage: '成人向け作品へ進む',
  },
  overAgeOnly: {
    id: 'adultBanner.overAgeOnly',
    defaultMessage: '18歳未満の方はご利用になれません',
  },
  viewingRightAlertTitle: {
    id: 'adultBanner.viewingRightAlertTitle',
    defaultMessage: 'H-NEXTの利用には、お手続きが必要です。',
  },
  viewingRightAlertOk: {
    id: 'adultBanner.viewingRightAlertOk',
    defaultMessage: '購入する',
  },
  viewingRightAlertText: {
    id: 'adultBanner.viewingRightAlertText',
    defaultMessage:
      '2024年6月21日よりH-NEXTの利用方法が変更になり、31日間パック購入による提供となりました。\n\n<strong>月額プランご利用中のお客様は、追加での費用負担が発生することはありませんので、ご安心ください。</strong>\n\n詳しくは<ma>こちら</ma>をご確認ください。\n\nH-NEXT見放題サービス（31日間パック）\n価格：440円（税込）\n<span>※月額プランご利用中の場合、追加での費用負担はありません。今月の月額料金から440円割引きされます。\n※月額プランの無料期間中は、特別特典により無料で購入できます。</span>\n\n利用可能期間：31日間',
  },
});

const HNEXT_SERVICE_DETAIL_URL =
  'https://help.unext.jp/guide/detail/whats-hnext-service';

const Layout = styled.div`
  position: relative;
  width: 100%;
  padding-top: 72px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
`;

const BackgroundImage = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const LayoutMask = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  backdrop-filter: blur(12px);

  ${({ theme }) => css`
    background: linear-gradient(
      -180deg,
      ${theme.background.gradient_40} 0%,
      ${theme.background.default} 100%
    );
  `}
`;

const ArtworkLayoutContainer = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 620px;
  padding: 0 40px 0;
  box-sizing: border-box;

  @media ${DEVICE.mobileWide} {
    padding: 0 16px 0;
  }
`;

const ArtworkLayout = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-flow: column;
  justify-self: center;
  align-items: center;
  padding: 24px 50px;
  background: ${({ theme }) => theme.semiAdult.artwork.background};
  border: 1px solid ${({ theme }) => theme.semiAdult.artwork.border};
  border-radius: 15px;

  @media ${DEVICE.mobileWide} {
    padding: 52px 32px;
  }
`;

const R18Img = styled(R18Icon)`
  width: 72px;
  height: 72px;
`;

const Text = styled.div`
  color: ${({ theme }) => theme.semiAdult.text.color};
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin: 14px 0 24px 0;

  @media ${DEVICE.mobileWide} {
    font-size: 14px;
  }
`;

const HNextButtonLink = styled.button<{ disabled: boolean }>`
  width: 100%;
  flex-grow: 1;
  max-width: 240px;
  transition: opacity 0.15s ease-in;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `};
`;

const HNextButton = styled((props) => (
  <LegacyNonStandardButton {...props} type="standard" />
))`
  border-radius: 28px;
  background: ${({ theme }) => theme.semiAdult.button.background};
  font-size: 18px;
  font-weight: 600;
  width: 100%;
  height: 56px;

  :hover {
    background: ${({ theme }) => theme.semiAdult.buttonHover.background};
    color: ${({ theme }) => theme.semiAdult.buttonHover.color};
  }

  @media ${DEVICE.mobileWide} {
    height: 48px;
  }
`;

const ButtonInside = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HNEXTButtonText = styled.span`
  vertical-align: middle;
`;

const Category = styled.div`
  width: 100%;
  &&& > * {
    margin-top: 48px;
    @media ${DEVICE.mobileWide} {
      margin-top: 60px;
    }
  }
`;

const AdultViewingRightAlertLinke = styled.a`
  text-decoration: underline;
`;

const AdultViewingRightAlertNote = styled.span`
  display: block;
  font-size: 14px;
  color: ${COLORS.white_50};
  margin-top: 6px;

  @media ${DEVICE.mobileWide} {
    font-size: 12px;
  }
`;

const AdultViewingRightAttentionText = styled.strong`
  color: ${COLORS.white};
  font-weight: 700;
`;

const AdultBanner: React.FC<{ searchGenreId: string; isNewUI: boolean }> = ({
  searchGenreId,
  isNewUI,
}) => {
  const intl = useIntl();
  const router = useRouter();
  const [showViewingRightAlert, setShowViewingRightAlert] = useState(false);
  const { getLoginUrl } = useLoginUrl();
  const { data: userInfoData } = useUserInfo();
  const isRedirectingRef = useRef(false);
  const isLoggedIn = !!userInfoData?.userInfo?.id;

  const { data: oneTimeTokenData, loading: oneTimeTokenLoading } =
    useClientQuery<cosmo_getOneTimeTokenQuery>(GET_ONE_TIME_TOKEN, {
      skip: !isLoggedIn,
      fetchPolicy: 'network-only',
    });

  const goToLoginPage = useCallback(() => {
    const loginUrl = getLoginUrl(router.asPath);
    isRedirectingRef.current = true;
    window.location.assign(loginUrl);
  }, [router.asPath, getLoginUrl]);

  const goToHNextPage = useCallback(() => {
    const ott = oneTimeTokenData?.webfront_oneTimeToken?.token;
    const redirectUri = ott
      ? `${GlobalConfig.URLS.ADULT_GATEWAY}?ott=${ott}`
      : GlobalConfig.URLS.ADULT_HOME;
    isRedirectingRef.current = true;
    window.location.assign(redirectUri);
  }, [oneTimeTokenData]);

  const [purchaseAdultRights, { loading: purchaseAdultRightsLoading }] =
    useMutation<
      cosmo_purchaseAdultRightsMutation,
      cosmo_purchaseAdultRightsMutationVariables
    >(PURCHASE_ADULT_RIGHTS, {
      onCompleted: () => {
        goToHNextPage();
      },
    });

  const handleHNextButtonClick = useCallback(() => {
    if (!isLoggedIn || !userInfoData.userInfo) {
      goToLoginPage();
      return;
    }
    const { isAdultPermitted, needsAdultViewingRights } = userInfoData.userInfo;
    if (isAdultPermitted && needsAdultViewingRights) {
      setShowViewingRightAlert(true);
      return;
    }
    goToHNextPage();
  }, [isLoggedIn, goToLoginPage, userInfoData, goToHNextPage]);

  const handleViewingRightAlertOk = useCallback(async () => {
    if (purchaseAdultRightsLoading || isRedirectingRef.current) return;
    await purchaseAdultRights();
  }, [purchaseAdultRights, purchaseAdultRightsLoading]);

  const handleViewingRightAlertCancel = useCallback(() => {
    setShowViewingRightAlert(false);
  }, []);

  return (
    <Layout>
      <picture>
        <BackgroundImage
          as="source"
          type="image/webp"
          srcSet={`//${ARTWORK_URL_SP}?output-format=webp&output-quality=70`}
          media={DEVICE.mobileWide}
        />
        <BackgroundImage
          as="source"
          type="image/png"
          srcSet={`//${ARTWORK_URL_SP}`}
          media={DEVICE.mobileWide}
        />
        <BackgroundImage
          as="source"
          type="image/webp"
          srcSet={`//${ARTWORK_URL_PC}?output-format=webp&output-quality=70`}
        />
        <BackgroundImage src={`//${ARTWORK_URL_PC}`} alt="" />
      </picture>
      <LayoutMask />
      <ArtworkLayoutContainer>
        <ArtworkLayout>
          <R18Img />
          <Text>
            {intl.formatMessage(adultBannerMessages.adultBannerCaution)}
          </Text>
          <HNextButtonLink
            onClick={handleHNextButtonClick}
            disabled={isLoggedIn && oneTimeTokenLoading}
          >
            <HNextButton>
              <ButtonInside>
                <HNEXTButtonText>
                  {intl.formatMessage(adultBannerMessages.adultBannerText)}
                </HNEXTButtonText>
              </ButtonInside>
            </HNextButton>
          </HNextButtonLink>
        </ArtworkLayout>
      </ArtworkLayoutContainer>
      <Category>
        <VideoCategoryList genreCode={searchGenreId} />
      </Category>
      {isNewUI && (
        <AdultFloatingButton
          onClick={handleHNextButtonClick}
          disabled={isLoggedIn && oneTimeTokenLoading}
        />
      )}
      <Modal
        visible={showViewingRightAlert}
        onOk={handleViewingRightAlertOk}
        onCancel={handleViewingRightAlertCancel}
        title={intl.formatMessage(adultBannerMessages.viewingRightAlertTitle)}
        okText={intl.formatMessage(adultBannerMessages.viewingRightAlertOk)}
        okButtonType="attention"
      >
        {intl.formatMessage(adultBannerMessages.viewingRightAlertText, {
          ma: (parts: ReactNode[]) => (
            <AdultViewingRightAlertLinke
              href={HNEXT_SERVICE_DETAIL_URL}
              target="_blank"
            >
              {parts}
            </AdultViewingRightAlertLinke>
          ),
          span: (parts: ReactNode[]) => (
            <AdultViewingRightAlertNote>{parts}</AdultViewingRightAlertNote>
          ),
          strong: (parts: ReactNode[]) => (
            <AdultViewingRightAttentionText>
              {parts}
            </AdultViewingRightAttentionText>
          ),
        })}
      </Modal>
    </Layout>
  );
};

export default AdultBanner;
