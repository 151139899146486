import React from 'react';
import styled from 'styled-components';
import { DEVICE } from '../../../styles';
import Popup from '../../Common/Popup';

export const MORE_OPTION_DROPDOWN_WIDTH = 344;
export const MORE_OPTION_DROPDOWN_WIDTH_MOBILE = 288;

const MoreOptionOverflowMenuLayout = styled.div`
  position: absolute;
  width: ${MORE_OPTION_DROPDOWN_WIDTH}px;
  margin-top: 4px;

  @media ${DEVICE.mobile} {
    width: ${MORE_OPTION_DROPDOWN_WIDTH_MOBILE}px;
  }
`;

const MoreOptionOverflowMenuItem = styled.button`
  padding: 0;
  color: ${({ theme }) => theme.text.linkHover};
  border: 0;
  border-radius: 8px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  text-align: center;
  font-weight: 600;
  background-color: ${({ theme }) => theme.popup.dropdownItemBackground};
  cursor: pointer;
  :hover,
  :focus {
    background-color: ${({ theme }) => theme.popup.backgroundActive};
  }

  &:not(:first-child) {
    margin-top: 8px;
  }
`;

interface MoreOptionOverflowMenuProps {
  items: React.ReactNodeArray;
  closeCallback: () => void;
  left: string;
  overflowMenuRef: React.RefObject<HTMLDivElement>;
}

const MoreOptionOverflowMenu: React.FC<MoreOptionOverflowMenuProps> = ({
  left,
  closeCallback,
  items,
  overflowMenuRef,
}) => {
  return (
    <MoreOptionOverflowMenuLayout
      style={{
        zIndex: 5002,
        left,
        marginTop: '-10px',
      }}
      ref={overflowMenuRef}
    >
      <Popup show>
        {items.map((item, index) => (
          <MoreOptionOverflowMenuItem
            key={`dropdown-${index}`}
            onClick={(e) => {
              closeCallback();
              e.stopPropagation();
            }}
          >
            {item}
          </MoreOptionOverflowMenuItem>
        ))}
      </Popup>
    </MoreOptionOverflowMenuLayout>
  );
};

export default MoreOptionOverflowMenu;
