import Bowser from 'bowser';
import { useContext } from 'react';
import UserAgentContext from '../UserAgentContext';

/**
 * useBrowser
 *
 * Uses the clients user agent (pulled from UserAgentContext) and creates
 * an instance of Bowser usable anywhere
 *
 * @returns Bowser Instance (Docs: http://lancedikson.github.io/bowser/docs/Bowser.html)
 */
const useBrowser = (): Bowser.Parser.Parser => {
  const { userAgent } = useContext(UserAgentContext);
  const browser = Bowser.getParser(userAgent);

  return browser;
};

export default useBrowser;
