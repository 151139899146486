import styled from 'styled-components';
import { ResumeCardType } from '..';
import { COLORS } from '../../../../styles/theme';
import { IMAGE_ASPECT } from '../../constants';

const PlayableThumbnailContainer = styled.div<{ type: ResumeCardType }>`
  display: block;
  position: relative;
  width: 100%;
  padding-bottom: ${({ type }) =>
    100 / (type === 'video' ? IMAGE_ASPECT.video : IMAGE_ASPECT.book)}%;
  background-color: ${COLORS.gray_3};
  cursor: pointer;

  img {
    position: absolute;
    width: 100%;
  }
`;

export default PlayableThumbnailContainer;
