import Link from 'next/link';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import {
  default as LegacyIcon,
  LegacyIcons,
} from '../../../shared/components/Common/Icon/LegacyIcon';
import {
  AMAZON_STORE_URL,
  APP_STORE_URL,
  GenreUrl,
  GlobalConfig,
  GOOGLE_STORE_URL,
} from '../../../shared/constants';
import { DEVICE } from '../../../shared/styles';
import { BLOCKS_MARGIN_MOBILE } from '../constants';

const messages = defineMessages({
  appDownload: {
    id: 'genre.appguidance.appdownload',
    defaultMessage: `アプリをダウンロード`,
  },
  couponTicket: {
    id: 'genre.appguidance.couponticket',
    defaultMessage: '映画チケット割引',
  },
  pointCharge: {
    id: 'genre.appguidance.pointcharge',
    defaultMessage: 'ポイントチャージ',
  },
  hnext: {
    id: 'genre.appguidance.hnext',
    defaultMessage: 'H-NEXT（その他）',
  },
});

const GuidanceButton = styled.a`
  display: flex;
  align-items: center;
  padding: 16px;
  height: 56px;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  border-radius: 2px;
  cursor: pointer;
  box-sizing: border-box;

  & > div {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  background: ${({ theme }) => theme.button.appGuidance.background};
  color: ${({ theme }) => theme.button.appGuidance.text};
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  position: relative;
  margin-bottom: ${BLOCKS_MARGIN_MOBILE}px;
  padding: 0 40px;
  text-align: center;

  & > ${GuidanceButton} {
    width: calc(50% - 8px);
    margin: 4px;
  }

  @media ${DEVICE.mobileWide} {
    padding: 0 11px;
  }
`;

const StyledIcon = styled(LegacyIcon)`
  width: 24px;
  height: 24px;
  > svg {
    top: 0;
  }
`;

interface AppGuidanceProps {
  storeType: 'ios' | 'google' | 'amazon';
  parentalLock?: boolean;
}

const AppGuidance: React.FC<AppGuidanceProps> = ({
  storeType,
  parentalLock,
}) => {
  return (
    <Container>
      <GuidanceButton
        href={
          storeType === 'ios'
            ? APP_STORE_URL
            : storeType === 'google'
            ? GOOGLE_STORE_URL
            : AMAZON_STORE_URL
        }
        target="_blank"
        rel="noopener noreferrer"
      >
        <StyledIcon name={LegacyIcons.AG_APP} />
        <FormattedMessage {...messages.appDownload} />
      </GuidanceButton>
      <GuidanceButton
        href={GlobalConfig.URLS.CINEMACOUPON}
        target="_self" // must open in the same tab
        rel="noopener noreferrer"
      >
        <StyledIcon name={LegacyIcons.AG_CINEMA} />
        <FormattedMessage {...messages.couponTicket} />
      </GuidanceButton>
      {!parentalLock && (
        <>
          <GuidanceButton href={`${GlobalConfig.URLS.MYACCOUNT}/pointcharge`}>
            <StyledIcon name={LegacyIcons.POINT} />
            <FormattedMessage {...messages.pointCharge} />
          </GuidanceButton>
          <Link href={`/genre/${GenreUrl.ADULT}`} passHref legacyBehavior>
            <GuidanceButton>
              <StyledIcon name={LegacyIcons.AG_HNEXT} />
              <FormattedMessage {...messages.hnext} />
            </GuidanceButton>
          </Link>
        </>
      )}
    </Container>
  );
};

export default AppGuidance;
