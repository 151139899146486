import Link from 'next/link';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { handleOnlyOnEnter } from '../../../../utils';
import { DEVICE } from '../../../styles';
import { COLORS } from '../../../styles/theme';
import LegacyIcon, { LegacyIcons } from '../../Common/Icon/LegacyIcon';
import ProgressBar from '../../Common/ProgressBar';
import {
  BLOCK_VIEW_MOBILE_TITLE_CARD_SIZE,
  BLOCK_VIEW_TITLE_CARD_SIZE,
} from '../constants';
import { BookThumbnail, VideoThumbnail } from './PlayableThumbnail';
import ResumeCardStage from './ResumeCardStage';

const ResumeCardContainer = styled.div<{ type: ResumeCardType }>`
  /* don't use position: relative here, it will break the "more option" popup */
  background: ${COLORS.ink};
  border-radius: 10px;
  box-sizing: border-box;
  border: 1px solid ${COLORS.white_10};
  overflow: hidden;

  width: ${({ type }) =>
    type === 'book'
      ? BLOCK_VIEW_TITLE_CARD_SIZE.resumeBook.width
      : BLOCK_VIEW_TITLE_CARD_SIZE.resumeVideo.width}px;

  @media ${DEVICE.mobileWide} {
    width: ${({ type }) =>
      type === 'book'
        ? BLOCK_VIEW_MOBILE_TITLE_CARD_SIZE.resumeBook.width
        : BLOCK_VIEW_MOBILE_TITLE_CARD_SIZE.resumeVideo.width}px;
  }
`;

const ResumeCardThumbnailContainer = styled.div<{ type: ResumeCardType }>`
  position: relative;
  ${({ type }) => (type === 'book' ? 'padding: 24px 24px 0;' : '')}

  @media ${DEVICE.mobileWide} {
    ${({ type }) => (type === 'book' ? 'padding: 18px 20px 0;' : '')}
  }
`;

const StyledProgressBar = styled(ProgressBar)`
  margin: 8px 12px 0;

  @media ${DEVICE.mobileWide} {
    margin: 8px 10px 0;
  }
`;

const MoreOptionOverflowMenuItemContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.white};
`;

const MoreOptionOverflowMenuItemText = styled.div`
  font-size: 14px;
`;

const MoreOptionOverflowMenuItemIcon = styled(LegacyIcon)`
  font-size: 15px;
  position: absolute;
  left: 16px;

  > svg {
    top: 0;
  }
`;

const TopRightText = styled.div`
  position: absolute;
  top: 6px;
  right: 8px;
  color: ${COLORS.primary};
  font-size: 10px;
  font-weight: 600;

  @media ${DEVICE.mobileWide} {
    top: 2px;
    right: 4px;
  }
`;

const messages = defineMessages({
  openTitleDetail: {
    id: 'genre.userblocks.moreOptions.openTitleDetail',
    defaultMessage: '作品詳細を見る',
  },
  deleteFromList: {
    id: 'genre.userblocks.moreOptions.deleteFromList',
    defaultMessage: '視聴履歴から削除',
  },
});

export type ResumeCardType = 'video' | 'book';

type ResumeCardBaseProps = {
  title: string;
  subtitle?: string;
  topRightText?: string;
  href: string;
  thumbnailUrl: string;
  thumbnailAlt: string;
  isActive: boolean;
  progressPercentage?: number;
  onClick: (e: React.MouseEvent | React.KeyboardEvent) => Promise<void>;
  onClickMoreOptionsButton: () => void;
  onClickOpenTitleDetail: () => void;
  onClickRemoveFromHistory: () => void;
};

type Props = ResumeCardBaseProps &
  (
    | {
        type: 'video';
      }
    | {
        type: 'book';
        isDailyFree: boolean;
        isDailyFreePlus: boolean;
      }
  );

const ResumeCard: React.FC<Props> = (props) => {
  const {
    type,
    title,
    subtitle,
    topRightText,
    href,
    thumbnailUrl,
    thumbnailAlt,
    isActive,
    progressPercentage,
    onClick,
    onClickMoreOptionsButton,
    onClickOpenTitleDetail,
    onClickRemoveFromHistory,
  } = props;

  return (
    <ResumeCardContainer type={type} data-ucn={`resumeCard-${type}`}>
      <ResumeCardThumbnailContainer type={type}>
        {topRightText && <TopRightText>{topRightText}</TopRightText>}
        <Link
          href={href}
          scroll={false}
          tabIndex={isActive ? undefined : -1}
          role="button"
          onKeyDown={(e) => {
            handleOnlyOnEnter(e, onClick);
          }}
          onClick={onClick}
          legacyBehavior={false}
          data-ucn={`resumeCard-${type}-link`}
        >
          {type === 'video' && (
            <VideoThumbnail
              thumbnailUrl={thumbnailUrl}
              thumbnailAlt={thumbnailAlt}
            />
          )}
          {type === 'book' && (
            <BookThumbnail
              thumbnailUrl={thumbnailUrl}
              thumbnailAlt={thumbnailAlt}
              isDailyFree={props.isDailyFree}
              isDailyFreePlus={props.isDailyFreePlus}
            />
          )}
        </Link>
      </ResumeCardThumbnailContainer>
      {progressPercentage !== undefined && (
        <StyledProgressBar percentage={progressPercentage} />
      )}
      <ResumeCardStage
        onClickMoreOptionsButton={onClickMoreOptionsButton}
        titleText={title}
        subTitleText={subtitle}
        items={[
          <MoreOptionOverflowMenuItemContent
            key="openTitleDetail"
            data-ucn="option-titleDetail-btn"
            onClick={onClickOpenTitleDetail}
          >
            <MoreOptionOverflowMenuItemIcon name={LegacyIcons.INFO_FILL} />
            <MoreOptionOverflowMenuItemText>
              <FormattedMessage {...messages.openTitleDetail} />
            </MoreOptionOverflowMenuItemText>
          </MoreOptionOverflowMenuItemContent>,
          <MoreOptionOverflowMenuItemContent
            key="deleteFromList"
            data-ucn="option-removeHistory-btn"
            onClick={onClickRemoveFromHistory}
          >
            <MoreOptionOverflowMenuItemIcon name={LegacyIcons.DELETE} />
            <MoreOptionOverflowMenuItemText>
              <FormattedMessage {...messages.deleteFromList} />
            </MoreOptionOverflowMenuItemText>
          </MoreOptionOverflowMenuItemContent>,
        ]}
      />
    </ResumeCardContainer>
  );
};

export default ResumeCard;
