import Link from 'next/link';
import React from 'react';
import { cosmo_homeQuery } from '../../../__generated__/globalTypes';
import Slider, {
  COUNT_BLOCK_CLASS_NAME,
} from '../../../shared/components/Slider';
import SimpleCard from '../../../shared/components/TitleCard/Simple';
import { LabelCode, LabelUrl } from '../../../shared/constants';
import type { HomeLabelBlockLabelCardLog } from '../../Log/__types__/home-labelBlock-labelCard';
import { getKafkaClient } from '../../Log/kafkaClient';
import BlockTitle from '../BlockTitle';
import { BlockContainer } from '../RecommendedBlocks';
import { getKafkaLogRowIndex, isActive } from '../utils';

const LabelBlock: React.FC<cosmo_homeQuery['labelBlock']> = ({
  name,
  labelList,
}) => {
  const keyPrefix = 'label-block-slider';
  return (
    <BlockContainer key={`label-block`} data-ucn="labelBlock">
      <BlockTitle.StyledLink as="div" hasNoLead>
        <BlockTitle.LinkText>{name}</BlockTitle.LinkText>
      </BlockTitle.StyledLink>
      <Slider
        type="label"
        keyPrefix={keyPrefix}
        items={({ activeRange }) => {
          const items = labelList.map((label, index) => {
            return (
              <Link
                key={label.code}
                href={`/brand/${LabelUrl[label.code as LabelCode]}`}
                tabIndex={isActive(index, activeRange) ? undefined : -1}
                onClick={() => {
                  getKafkaClient().trackUserClickDimension2<HomeLabelBlockLabelCardLog>(
                    'home-labelBlock-labelCard',
                    {
                      column_index: index,
                      label_genre_code: label.genreCode,
                      row_index: getKafkaLogRowIndex(
                        COUNT_BLOCK_CLASS_NAME,
                        keyPrefix
                      ),
                    }
                  );
                }}
                legacyBehavior={false}
                data-ucn="labelBlock-item-link"
              >
                <SimpleCard
                  thumbnailUrl={`//${label.thumbnail.standard}`}
                  name={label.name}
                  type="label"
                />
              </Link>
            );
          });
          return items;
        }}
      />
    </BlockContainer>
  );
};

export default LabelBlock;
