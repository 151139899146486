import React from 'react';
import styled from 'styled-components';
import { cosmo_leadTitleQuery } from '../../../__generated__/globalTypes';
import BackgroundPattern from '../../../shared/components/BackgroundPattern';
import { Button } from '../../../shared/components/Common/Button';
import TitleLink from '../../../shared/components/TitleLink';
import { DEVICE } from '../../../shared/styles';
import type { HomeLeadTitleBlockMoreLog } from '../../Log/__types__/home-leadTitleBlock-more';
import { getKafkaClient } from '../../Log/kafkaClient';

const IMAGE_ASPECT = {
  MOBILE: 10 / 11,
  PC: 64 / 25,
};

const GenreLeadSpacerTextPlaceholder = styled.div`
  @media ${DEVICE.mobileWide} {
    height: 90px;
  }
`;

export const GenreLeadSpacer: React.VoidFunctionComponent = () => {
  return (
    <LeadContainer>
      <BackgroundPattern />
      <GenreLeadSpacerTextPlaceholder />
    </LeadContainer>
  );
};

/**
 * WF-13403
 * desktopの場合、LeadContainerを基準として、その下のBlockをレイアウトする
 * mobileの場合、「詳しく見る」ボタンを基準として、その下にBlockをレイアウトする
 * それを実現するために、LeadContainerとContentLayoutのサイズ・配置を、desktopかmobileかで切り替える
 *
 * テキスト領域の仕様 https://wiki.unext-info.jp/pages/viewpage.action?pageId=72892591
 */
const LeadContainer = styled.div`
  width: 100%;
  position: relative;
  // desktopの場合、LeadContainerの高さは aspect-ratio: 192 / 61 で決定する
  padding-top: ${(61 / 192) * 100}%;

  @media ${DEVICE.mobileWide} {
    // mobileの場合、テキスト領域を画像の高さの75%の位置にレイアウトするため、LeadContainerの高さを画像の高さの75%とする
    padding-top: ${75 / IMAGE_ASPECT.MOBILE}%;
  }
`;

const ContentLayout = styled.div`
  // desktopの場合、テキスト領域を画像の高さの50%の位置にテキスト表示
  position: absolute;
  top: 0;
  width: 100%;
  padding-top: ${50 / IMAGE_ASPECT.PC}%;

  @media ${DEVICE.mobileWide} {
    // mobileの場合、テキスト領域のレイアウトはLeadContainerが担当するため、ContentLayoutのレイアウトをリセットする
    position: relative;
    padding-top: 0;
  }
`;

const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-bottom: ${100 / IMAGE_ASPECT.PC}%;

  @media ${DEVICE.mobileWide} {
    padding-bottom: ${100 / IMAGE_ASPECT.MOBILE}%;
  }
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const TextButtonContainer = styled.div`
  // PCは、leftから5%の位置に、幅39%でテキスト表示
  // MOBILEは、leftから0%の位置に、幅100%でテキスト表示
  z-index: 1;
  margin-left: 5%;
  width: 39%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${DEVICE.mobileWide} {
    box-sizing: border-box;
    padding: 12px 64px 0;
    margin-left: 0;
    width: 100%;
  }

  @media ${DEVICE.mobile} {
    padding: 12px 32px 0;
  }

  button {
    margin-top: 12px;
    font-size: 14px;
    width: 180px;
    height: 40px;

    @media ${DEVICE.sd} {
      width: 128px;
    }
  }
`;

const LeadText = styled.div`
  color: ${({ theme }) => theme.text.standard};
  text-align: center;
  font-size: 14px;
`;

interface GenreLeadProps {
  thumbnail: cosmo_leadTitleQuery['webfront_leadTitle']['thumbnail'];
  text: cosmo_leadTitleQuery['webfront_leadTitle']['text'];
  titleCode: cosmo_leadTitleQuery['webfront_leadTitle']['titleCode'];
  leadCode: cosmo_leadTitleQuery['webfront_leadTitle']['leadCode'];
}

const Lead: React.FC<GenreLeadProps> = ({
  text,
  thumbnail,
  titleCode,
  leadCode,
}) => {
  return (
    <LeadContainer data-ucn="genreLead">
      <ImageContainer>
        <picture>
          <Image
            as="source"
            type="image/webp"
            srcSet={`//${thumbnail.small}?output-format=webp&output-quality=70`}
            media={DEVICE.mobileWide}
          />
          <Image
            as="source"
            type="image/png"
            srcSet={`//${thumbnail.small}`}
            media={DEVICE.mobileWide}
          />
          <Image
            as="source"
            type="image/webp"
            srcSet={`//${thumbnail.large}?output-format=webp&output-quality=70`}
          />
          <Image src={`//${thumbnail.large}`} alt="" />
        </picture>
      </ImageContainer>
      <ContentLayout>
        <TextButtonContainer>
          <LeadText>{text}</LeadText>
          <TitleLink type="video" titleCode={titleCode}>
            <Button
              buttonTheme="ghost"
              buttonSize="medium"
              data-ucn="leadTitle-btn"
              onClick={() => {
                getKafkaClient().trackUserClickDimension0<HomeLeadTitleBlockMoreLog>(
                  'home-leadTitleBlock-more',
                  {
                    lead_sakuhin_code: leadCode,
                  }
                );
              }}
              text="詳しく見る"
            />
          </TitleLink>
        </TextButtonContainer>
      </ContentLayout>
    </LeadContainer>
  );
};

export default Lead;
